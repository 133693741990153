import { useCallback, useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Layout } from '@curawella/admin-package';

import './App.scss';
import { ctx } from './contexts/userContext';
import { userAdminType } from './models/admins';
import LoginModel from './models/login';

import Login from './pages/Login/Login';

import Admins from './pages/admins/Admins';
import ViewAdmin from './pages/admins/ViewAdmin';
import CreateAdmin from './pages/admins/CreateAdmin';

import Doctors from './pages/doctors/Doctors';
import Doctor from './pages/doctor/Doctor';

import Patients from './pages/patients/Patients';
import Patient from './pages/patient';
import Appointments from './pages/patient/Appointments';
import CashBox from './pages/patient/CashBox';

import Approvement from './pages/approvement/approvement';
import ViewApprovement from './pages/approvement/ViewApprovement';

import ContactUs from './pages/contactUs/ContactUS';
import ContactUsDetails from './pages/ContactUsDetails/ContactUsDetails';

import Notifications from './pages/notifications/Notifications';
import AddNotificationApp from './pages/notifications/add-notification-app/AddNotificationApp';

import Earnings from './pages/Earnings/Earnings';

import Companies from './pages/companies/Companies';

import Subscriptions from './pages/subscriptions/Subscriptions';
import SubscriptionDetails from './pages/subscriptions/subscription-details/SubscriptionDetails';

import { SidebarLinksType } from '@curawella/admin-package/dist/lib/constants/Types';
import Orders from './pages/Orders';
import SubscribeDoctor from './pages/subscriptions/subscribe-doctor/SubscribeDoctor';
import PaymentHistory from './pages/subscriptions/payment-history/PaymentHistory';
import AdminProfile from './pages/admin-profile/AdminProfile';
import ChangeAdminPass from './pages/admin-profile/ChangeAdminPass';

import Emails from './pages/emails/Emails';
import SendEmail from './pages/emails/sendEmail';
import EmailDetails from './pages/emails/EmailDetails';
import Drugs from './pages/drugs/Drugs';
import AddDrug from './pages/drugs/AddDrug';
import DrugDetails from './pages/drugs/DrugDetails';

function App(): JSX.Element {
    const [activePage, setActivePage] = useState<string>('');
    const location = useLocation();
    const { user, setUser } = useContext(ctx);
    const [logoutLoading, executeLogout] = LoginModel.logoutUser();
    const routes = handleRoutes(user);

    const handleLogout = useCallback((): void => {
        if (!user) {
            return;
        }

        executeLogout({ uid: user.uid })
            .then(() => setUser(null))
            .catch((err: any) => console.log('logout error: ', err));
    }, [executeLogout, setUser, user]);

    const checkCurrentPage = useCallback(() => {
        const currentPage = window.location.pathname;
        setActivePage(currentPage.slice(1));
    }, []);

    useEffect(() => {
        checkCurrentPage();
        moment.locale('en');
    }, [checkCurrentPage, location]);

    console.log(user, window.location.pathname);

    if (logoutLoading) {
        return <>loading...</>;
    }

    if (!user.uid) {
        return (
            <div className='app-container'>
                <Route exact path='/' component={Login} />
                <Redirect to='/' />
            </div>
        );
    }
    return (
        <div className='app-container'>
            <Layout
                links={routes}
                active={activePage}
                logout={{ action: () => handleLogout(), icon: '/assets/sidebar/logout.png' }}
                title={`${user.firstName} ${user.lastName}`}
                logoSrc='/logo192.png'>
                <Switch>
                    <Route exact path='/profile' component={AdminProfile} />
                    <Route exact path='/changeAdminPass' component={ChangeAdminPass} />

                    <Route exact path='/admins' component={Admins} />
                    <Route exact path='/admins/create' component={CreateAdmin} />
                    <Route exact path='/admins/:adminUid' component={ViewAdmin} />

                    <Route exact path='/doctors' component={Doctors} />
                    <Route exact path='/doctor/:doctorUid' component={Doctor} />

                    <Route exact path='/patients' component={Patients} />
                    <Route exact path='/patient/:patientUid' component={Patient} />
                    <Route exact path='/patient/:patientUid/appointments' component={Appointments} />
                    <Route exact path='/patient/:patientUid/cash-box' component={CashBox} />

                    <Route exact path='/approvement' component={Approvement} />
                    <Route exact path='/approvement/:companyId/:elementType/:elementId' component={ViewApprovement} />

                    <Route exact path='/contactUsDetails' component={ContactUsDetails} />
                    <Route exact path='/contactus' component={ContactUs} />

                    <Route exact path='/emails' component={Emails} />
                    <Route exact path='/sendEmail' component={SendEmail} />
                    <Route exact path='/emails/:emailId' component={EmailDetails} />

                    <Route exact path='/notifications' component={Notifications} />
                    <Route exact path='/notifications/add' component={AddNotificationApp} />

                    <Route exact path='/earnings' component={Earnings} />
                    <Route exact path='/orders' component={Orders} />

                    <Route path='/companies' component={Companies} />

                    <Route exact path='/subscriptions' component={Subscriptions} />
                    <Route exact path='/subscriptions/add' component={SubscribeDoctor} />
                    <Route exact path='/subscriptions/details/:doctorUid' component={SubscriptionDetails} />
                    <Route exact path='/subscriptions/payment-history/:doctorUid' component={PaymentHistory} />

                    <Route exact path='/drugs' component={Drugs} />
                    <Route exact path='/addDrug' component={AddDrug} />
                    <Route exact path='/drugs/:drugId' component={DrugDetails} />

                    <Redirect to={'/profile'} />
                </Switch>
            </Layout>
        </div>
    );
}

export default App;

function handleRoutes(user: userAdminType): SidebarLinksType {
    const { ADMINS, CONTACTUS, DOCTORS, DRUGS, EARNINGS, NOTIFICATIONS, PATIENTS, SUBSCRIPTION } = user;

    const arr: SidebarLinksType = [];

    if (ADMINS) {
        arr.push({ title: 'Your Profile', path: '/profile', icon: '/assets/sidebar/admin.svg' });
    }

    if (ADMINS) {
        arr.push({ title: 'Admins', path: '/admins', icon: '/assets/sidebar/admin.svg' });
    }

    if (PATIENTS) {
        arr.push({ title: 'Patients', path: '/patients', icon: '/assets/sidebar/patient.svg' });
    }

    if (DOCTORS) {
        arr.push({ title: 'Doctors', path: '/doctors', icon: '/assets/sidebar/employee.svg' });
    }

    if (EARNINGS) {
        arr.push({ title: 'Earnings', path: '/earnings', icon: '/assets/sidebar/earning.svg' });
        arr.push({ title: 'Orders', path: '/orders', icon: '/assets/sidebar/orders.svg' });
    }

    if (NOTIFICATIONS) {
        arr.push({ title: 'Notifications', path: '/notifications', icon: '/assets/sidebar/notification.svg' });
    }

    if (CONTACTUS) {
        arr.push({ title: 'Contact us', path: '/contactUs', icon: '/assets/sidebar/contact-us.svg' });
        arr.push({ title: 'Emails', path: '/emails', icon: '/assets/sidebar/contact-us.svg' });
    }

    if (DRUGS) {
        arr.push({ title: 'Drugs', path: '/drugs', icon: '/assets/sidebar/drugs.png' });
    }

    if (SUBSCRIPTION) {
        arr.push({ title: 'Subscriptions', path: '/subscriptions', icon: '/assets/sidebar/earning.svg' });
    }

    if (false) {
        arr.push({ title: 'Companies', path: '/companies/view' });
        arr.push({ title: 'Approvement', path: '/approvement' });
    }

    return arr;
}
