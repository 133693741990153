// eslint-disable-next-line @typescript-eslint/no-var-requires
const countrycitystatejson = require('countrycitystatejson');

// export function GetCountries(): string[] {
export function GetCountries(): Record<string, string> {
  // get all countries data array
  const allCountries: { [key: string]: any }[] = countrycitystatejson.getCountries();

  // get countries names
  const nativeCountries: Record<string, string> = getNativeCountries(allCountries, 'native');

  return nativeCountries;
}

// return object of countries (native names)
function getNativeCountries(input: { [key: string]: any }[], field: string): Record<string, string> {
  let output: Record<string, string> = {};
  for (let i = 0; i < input.length; ++i) {
    const countryName = input[i][field]
    output = { ...output, [countryName]: countryName }
  }
  return output;
}
