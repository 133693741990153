import React, { useCallback, useEffect } from 'react';

import { Header } from '@curawella/admin-package';
import Loading from '../../components/Loading';
import RenderChart, { fillMissingDates } from '../../components/Chart';
import { refactoringOrders } from '../../components/Orders';
import OrderModel from '../../models/Orders';

const Orders = (): JSX.Element => {
    const [doctorLoading, executeDoctor, data] = OrderModel.get();
    const growth = data?.growth || [];

    /**
     * Get doctor data
     */
    const getData = useCallback(() => {
        executeDoctor({});
    }, [executeDoctor]);

    // Call getData function to get doctor data
    useEffect(() => {
        getData();
    }, [getData]);

    if (doctorLoading) {
        return <Loading />;
    }

    return (
        <>
            {/* header */}
            <Header title={`Orders`} />

            <RenderChart title='Orders' headers={['amount', 'online', 'onsite']} data={growth ? fillMissingDates(refactoringOrders(growth)) : []} />
        </>
    );
};

export default Orders;
