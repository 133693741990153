import { Method } from "axios";
import { DataViewItem, DataViewItemTypes, Input, InputTypes } from "@curawella/admin-package";

export type objType = Record<string | number, string | number>
export type inputsType = {
    key: string;
    label: string;
    type?: InputTypes;
    value?: string;
    options?: objType;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    maxDate?: string;
    minDate?: string
    error?: string;
}

export type customDataType = {
    type?: DataViewItemTypes;
    title?: string;
    value?: string | { link: string }[] | boolean;
    editable?: boolean;
    endPoint?: string;
    method?: Method;
    inputs?: inputsType[];
    uploadImage?: (compressedImg: File) => Promise<string>;
} | undefined

export function HandleData(data: { [key: string]: any }, customData?: { [k: string]: customDataType }, endpoint?: string): DataViewItem[] {
    const newData: DataViewItem[] = []
    for (const key in customData) {
        const element = customData[key]

        if (element === undefined) {
            continue
        }

        const { type, title, editable, method, inputs: inputData, value, uploadImage } = element
        const modalInputs: Input[] = []


        // inputValue same as database by default 
        let inputValue = data[key]

        // override inputValue if provided in configuration
        if (typeof value !== 'undefined') {
            inputValue = value
        }

        if (typeof inputData !== 'undefined') {
            for (let i = 0; i < inputData.length; i++) {
                const inputElement = inputData[i];

                const newInput: Input = {
                    key: inputElement.key || key,
                    label: inputElement.key.toUpperCase() || '',
                    type: inputElement.type || InputTypes.TEXT,
                    value: typeof inputElement.value !== 'undefined' ? inputElement.value : inputValue,
                    options: HandleObject(inputElement.options),
                    handleChange: inputElement.handleChange,
                    maxDate: inputElement.maxDate || undefined,
                    minDate: inputElement.minDate || undefined,
                    error: inputElement.error || undefined
                }

                modalInputs.push(newInput)
            }
        } else {
            modalInputs.push({ key, label: key.toUpperCase(), type: InputTypes.TEXT, value: inputValue })
        }

        const defaultValue: DataViewItem = {
            type: type || DataViewItemTypes.TEXT,
            title: title || String(key).toUpperCase(),
            value: inputValue,
            editable: typeof editable === 'undefined' ? true : editable,
            endPoint: `${endpoint}${key}`,
            method: method || 'PUT',
            inputs: modalInputs,
            uploadImage: uploadImage,
        };

        newData.push(defaultValue)
    }

    return newData;
}

export function HandleObject(objData: objType = {}): objType[] {
    const arr: objType[] = [];

    for (const key in objData) {
        const element = objData[key];
        arr.push({ [key]: element });
    }

    return arr
}