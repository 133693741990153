import { DetailsView, Loading } from '@curawella/admin-package';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DrugsModel from '../../models/Drug';

const DrugDetails = () => {
    const { drugId } = useParams<{ drugId: string }>();

    const [loading, execute, data] = DrugsModel.getDrug();
    const [loadingDisable, executeDisable] = DrugsModel.disableDrug();
    const { active, commercialName, dosageForm, dose, labelerName, ndc, route, scientificName, type } = data || {};

    const getDetails = useCallback((): void => {
        execute({ drugId });
    }, [execute, drugId]);

    useEffect(() => getDetails(), [getDetails]);

    const onSubmit = (e: any, data: Record<string, string | boolean>, key: string) => {
        if (key !== 'active') return;
        executeDisable({ drugId, ...data }).then(() => getDetails());
    };

    if (loading || loadingDisable) {
        return <Loading />;
    }
    return (
        <div>
            <DetailsView
                onSubmit={onSubmit}
                content={{
                    drugId: { value: drugId },
                    ndc: { value: ndc },
                    commercialName: { value: commercialName },
                    scientificName: { value: scientificName },
                    dosageForm: { value: dosageForm },
                    dose: { value: dose },
                    route: { value: route },
                    type: { value: type },
                    labelerName: { value: labelerName },
                    active: { value: active === 1, type: 'TOGGLE' },
                }}
            />
        </div>
    );
};

export default DrugDetails;
