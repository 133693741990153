// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nq2G23EoANGVTBi9AHN0{display:flex;flex-direction:column}.Nq2G23EoANGVTBi9AHN0 ._7K6B1h6SloXLokkqsTet{align-self:flex-start;background-color:#01b1be;color:#fff;margin-top:1rem}", "",{"version":3,"sources":["webpack://./src/components/FormLayout/FormLayout.module.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,qBAAA,CACA,6CACI,qBAAA,CACA,wBCME,CDLF,UAAA,CACA,eAAA","sourcesContent":["@import '../../assets/styles/colors.scss';\n\n.form {\n    display: flex;\n    flex-direction: column;\n    .submit-btn {\n        align-self: flex-start;\n        background-color: $primary;\n        color: white;\n        margin-top: 1rem;\n    }\n}\n","// $primary: #31c2e1;\n// $secondary: #44a8de;\n\n// $gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);\n\n// $bg: #f8fefe;\n\n// $border: #f0fbfd;\n\n$text-dark: #434343;\n\n// $text-light: #808080;\n\n$primary: #01b1be;\n$secondary: #029ca8;\n$input-shadow: #01b1be25;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Nq2G23EoANGVTBi9AHN0",
	"submit-btn": "_7K6B1h6SloXLokkqsTet"
};
export default ___CSS_LOADER_EXPORT___;
