import { useCallback, useEffect, useState } from 'react';
import { Btn, TableComp } from '@curawella/admin-package';
import moment from 'moment';
import Select from '../../components/Select/Select';
import SubscriptionsModel, { getSubscriptionsListType } from '../../models/Subscriptions';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SVG from 'react-inlinesvg';
import { DateRangePicker } from 'react-date-range';
import styles from './Subscriptions.module.scss';
import Loading from '../../components/Loading';
import { useHistory } from 'react-router-dom';
import { getStatus } from './subscription-details/SubscriptionDetails';

const pageLength = 5;

const selectOptions = ['ALL', 'REQUESTED', 'APPROVED', 'DEVELOPED', 'RELEASED', 'CANCELED'];

type RangeType = {
    from: string;
    to: string;
};
const today = new Date();

const Subscriptions = () => {
    const history = useHistory();
    const [selectionRange, setSelectionRange] = useState<RangeType | undefined>();
    const [show, setShow] = useState<boolean>(false);
    const [loading, execute, data] = SubscriptionsModel.getSubscriptionsList();

    const subscriptions = data?.results || [];
    const [selected, setSelected] = useState(selectOptions[0]);
    /**
     * Get subscriptions list
     */
    const getSubscriptionsList = useCallback(
        (pageNumber = undefined) => {
            const d: getSubscriptionsListType = { nextPaymentDue: selectionRange, pageSize: pageLength };

            if (pageNumber) {
                d.pageNumber = pageNumber;
            }
            if (selected !== 'ALL') {
                d.status = selected;
            }

            execute(d);
        },
        [execute, selected, selectionRange],
    );

    // Call getSubscriptionsList function to get subscriptions list
    useEffect(() => {
        getSubscriptionsList();
    }, [getSubscriptionsList]);

    /**
     * set ranges value
     */
    const handleSelect = (ranges: any) => {
        const { startDate, endDate } = ranges.selection;
        setSelectionRange({ from: moment(startDate).startOf('day').format('YYYY-MM-DD'), to: moment(endDate).startOf('day').format('YYYY-MM-DD') });
        setShow(!show);
    };

    return (
        <div className={styles['subscriptions-container']}>
            <div className={styles['head']}>
                <Select selectedOption={selected} options={selectOptions} handleChange={(selected) => setSelected(selected)} />

                <div className={styles['date-range']} onClick={() => setShow(!show)}>
                    <SVG className={styles['calender']} src='/assets/Calendar.svg' />
                    {selectionRange ? <p>{`${selectionRange.from} - ${selectionRange.to}`}</p> : 'Select date'}
                </div>
                <Btn className={styles.btn} active handleClick={() => history.push('/subscriptions/add')}>
                    Subscribe Doctor
                </Btn>
            </div>
            <div className={`${styles['time-piker']} ${show ? styles['show'] : ''}`}>
                <DateRangePicker
                    className={styles['calender-table']}
                    direction='horizontal'
                    ranges={[
                        {
                            key: 'selection',
                            startDate: selectionRange ? moment(selectionRange.from, 'YYYYY-MM-DD').toDate() : today,
                            endDate: selectionRange ? moment(selectionRange.to, 'YYYYY-MM-DD').toDate() : today,
                        },
                    ]}
                    onChange={handleSelect}
                />
            </div>
            {loading && <Loading />}
            <TableComp
                title={'Subscriptions List'}
                headers={[
                    { key: 'name' },
                    { key: 'recurrent' },
                    { key: 'price', format: (v) => `${Number(v).toFixed(0)}EGP` },
                    { key: 'nextPaymentDue', title: 'Next Payment', format: (v: string) => (v ? moment(v).format('DD/MM/YYYY h:mmA') : '') },
                    { key: 'paymentMethod', title: 'P.Method' },
                    { key: 'status' },
                ]}
                data={subscriptions.map((v: any) => ({ ...v, name: `${v.firstName}${' '}${v.lastName}`, status: getStatus(v).currentStatus }))}
                pagination={{ pageLength }}
                showDataLength
                dataLength={data?.length}
                rowAction={(v) => history.push({ pathname: `/subscriptions/details/${v.uid}` })}
                endpoint={getSubscriptionsList}
            />
        </div>
    );
};

export default Subscriptions;
