import moment from 'moment';

export enum PAYMENT_METHODS {
    AGGREGATOR = 'AGGREGATOR',
    WALLET = 'WALLET',
    TOKEN = 'TOKEN',
    ONSITE = 'ONSITE',
    CASH_BOX = 'CASH_BOX',
    CARD = 'CARD',
    SAVE_CARD = 'SAVE_CARD',
    VAL_U = 'VAL_U',
}
export function refactoringOrders(orders: { count: number; paymentMethod: PAYMENT_METHODS; date: string; total: number }[]) {
    const temp: ({ date: string } & Record<string, any>)[] = [];

    for (let i = 0; i < orders.length; i++) {
        const { count, date, paymentMethod, total } = orders[i];

        let online = 0;
        let onsite = 0;
        if (paymentMethod === PAYMENT_METHODS.ONSITE) {
            onsite = count;
        } else {
            online = count;
        }

        temp.push({ date, onsite, online, paymentMethod, amount: Number(total / 100000).toFixed(3) });
    }
    const finalArr = [];

    for (let i = 0; i < temp.length; i++) {
        const element = temp[i];
        const obj = { ...element };
        for (let j = i + 1; j < temp.length; j++) {
            const nextElement = temp[j];

            if (moment(element.date).isSame(nextElement.date)) {
                obj.online += nextElement.online;
                obj.onsite += nextElement.onsite;
                obj.amount += nextElement.amount;
            } else {
                break;
            }
        }
        finalArr.push(obj);
    }

    return finalArr;
}
