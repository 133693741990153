import { useFetchType } from "react-usefetch-models/build/types";
import Backend from "./backendConfig";

export type loginUserType = { credential: string; password: string; };
export type resetPasswordType = { uid: string; };
export type getUserType = { uid: string };
export type logoutUserType = { uid: string };


class LoginModel {
    static loginUser(): useFetchType<loginUserType> {
        const bodyDefault = {
            credential: '', password: ''
        };
        const endPoint = 'admin/auth/login';
        return Backend.config.postData<loginUserType>({
            endPoint,
            bodyDefault
        })

    }

    static getUser(): useFetchType<getUserType> {
        const endPoint = 'admin/auth/:uid';
        return Backend.config.getData<getUserType>({
            endPoint
        })

    }

    static passwordReset(): useFetchType<resetPasswordType> {
        const endPoint = 'admin/admins/:uid/resetPassword';

        return Backend.config.updateData<resetPasswordType>({
            endPoint

        })
    }

    static logoutUser(): useFetchType<logoutUserType> {
        const endPoint = 'admin/auth/logout';
        const bodyDefault = {
            uid: ''
        };

        return Backend.config.postData<logoutUserType>({
            endPoint,
            bodyDefault
        })

    }

}


export default LoginModel