import {FormInputs, useFetch} from '@curawella/admin-package';
import {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {customApi, pharmaUsersTypes} from '../../../../constants';
import styles from './CompaniesUsersCreate.module.scss';

const CompaniesUsersCreate = (): JSX.Element => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        position: ''
    });
    const [formErrors, setFormErrors] = useState<{[key: string]: {[value: string]: null | boolean | string}}>({
        firstName: {
            value: null,
            msg: null
        },
        lastName: {
            value: null,
            msg: null
        },
        email: {
            value: null,
            msg: null
        },
        phone: {
            value: null,
            msg: null
        },
        position: {
            value: null,
            msg: null
        }
    });
    const history = useHistory();
    const {companyId} = useParams<{companyId: string}>();

    const [data, loading, error, execute] = useFetch();

    const forms = [
        {
            name: 'First Name',
            type: 'text',
            value: form.firstName,
            name2: 'firstName',
            check: formErrors.firstName
        },
        {
            name: 'Last Name',
            type: 'text',
            value: form.lastName,
            name2: 'lastName',
            check: formErrors.lastName
        },
        {
            name: 'Email',
            type: 'text',
            value: form.email,
            name2: 'email',
            check: formErrors.email
        },
        {
            name: 'Phone',
            type: 'text',
            value: form.phone,
            name2: 'phone',
            check: formErrors.phone
        },
        {
            name: 'Position',
            type: 'select',
            value: form.position,
            name2: 'position',
            check: formErrors.position,
            options: pharmaUsersTypes
        }
    ];

    // "firstName": "Mostafa",
    // "lastName": "Mahmoud",
    // "email": "mostafa@curawella.com",
    // "phone": "01113206619",
    // "position": "CTO"

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const data = {...form, [e.currentTarget.name]: e.currentTarget.value};
        setForm(data);
    };

    // =================NAVIGATE TO VIEW ALL COMPANIES=================
    const navigateToViewAll = useCallback(() => {
        history.push(`/companies/${companyId}/users`);
    }, [companyId, history]);

    // =================SUBMIT ADD COMPANY=================
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('form: ', form);
        setFormErrors({
            firstName: {
                value: null,
                msg: null
            },
            lastName: {
                value: null,
                msg: null
            },
            email: {
                value: null,
                msg: null
            },
            phone: {
                value: null,
                msg: null
            },
            position: {
                value: null,
                msg: null
            }
        });

        // VALIDATE INPUTS HERE
        // if (!form.name) {
        //     setFormErrors({name: {value: false, msg: 'Required'}});
        //     return;
        // }

        // {{API}}/admin/users/:companyId
        execute('post', `admin/users/${companyId}`, form, customApi);
    };

    // =================ADD COMPANY DATA RECEIVED=================
    useEffect(() => {
        console.log('data: ', data);
        if (Object.keys(data).length) {
            alert('added successfully');
            navigateToViewAll();
        }
    }, [data, navigateToViewAll]);

    // =================ADD NOTIFICATION DATA ERROR=================
    useEffect(() => {
        console.log('error: ', error);
    }, [error]);

    return (
        <div className={styles['companies-view-container']}>
            <h1>Create User</h1>
            <button onClick={() => history.push(`/companies/${companyId}/users`)}>Users</button>

            {loading && <p>loading...</p>}

            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'submit'} />
            </Form>
        </div>
    );
};

export default CompaniesUsersCreate;
