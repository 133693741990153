import React from 'react';
import { useParams } from 'react-router-dom';

import styles from './Doctor.module.scss';

import Earnings from './Earnings';
import DataDetails from './DataDetails';

const Doctor = (): JSX.Element => {
    const params: { doctorUid: string } = useParams();
    const doctorUid: string = params.doctorUid;

    return (
        <div className={styles['doctor-container']}>
            <DataDetails doctorUid={doctorUid} />
            <Earnings doctorUid={doctorUid} />
        </div>
    );
};

export default Doctor;
