import { useFetchType } from 'react-usefetch-models/build/types';
import Backend from './backendConfig';

export type userAdminType = {
    uid?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    position: string;
    ADMINS: 0 | 1;
    CONTACTUS: 0 | 1;
    DOCTORS: 0 | 1;
    DRUGS: 0 | 1;
    SUBSCRIPTION: 0 | 1;
    EARNINGS: 0 | 1;
    NOTIFICATIONS: 0 | 1;
    PATIENTS: 0 | 1;
    SPECIALTIES: 0 | 1;
    isActive: 0 | 1;
};

export type createAdminType = { firstName: string; lastName: string; email: string; phone: string; position: string };
export type getAdminDetails = { adminUid: string };
export type changePassType = { uid: string; password: string; newPassword: string };
class AdminsModal {
    // ===================================================GET ADMINS=====================================================
    static getAdmins(): useFetchType {
        const endPoint = 'admin/admins';
        return Backend.config.getData({
            endPoint,
        });
    }

    // ===================================================CREATE ADMIN=====================================================
    static createAdmin(): useFetchType<createAdminType> {
        const endPoint = 'admin/admins';

        const bodyDefault = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            position: '',
        };
        return Backend.config.postData<createAdminType>({
            endPoint,
            bodyDefault,
        });
    }

    // ===================================================GET ADMIN DETAILS=====================================================
    static getAdminDetails(): useFetchType<getAdminDetails> {
        const endPoint = 'admin/admins/:adminUid';

        return Backend.config.getData<getAdminDetails>({ endPoint });
    }
    //===================================================Change Admin Password=================================================
    static changePass(): useFetchType<changePassType> {
        const endPoint = 'admin/auth/:uid';
        const bodyDefault = {
            password: '',
            newPassword: '',
        };
        return Backend.config.postData<changePassType>({ endPoint, bodyDefault });
    }
}

export default AdminsModal;
