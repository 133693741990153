import React, {useState, useMemo, createContext} from 'react';

export const ctx = createContext<{user: any; setUser: React.Dispatch<any>}>({
    user: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: () => {}
});

const ContextProvider: React.FC = (props) => {
    const [user, setUser] = useState<any>({});
    // eslint-disable-next-line react/prop-types
    const {children} = props;
    const values = useMemo(() => ({user, setUser}), [user]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <ctx.Provider value={values}>{children}</ctx.Provider>;
};

export default ContextProvider;
