import React from 'react';
import { FormLayout, Patterns, formInputTypesEnum, modalAlert } from '@curawella/admin-package';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import SubscriptionsModel, { paymentMethodOptions, recurrentOptions } from '../../../models/Subscriptions';
import styles from '../Subscriptions.module.scss';
import DoctorModel from '../../../models/Doctor';

const SubscribeDoctor = () => {
    const [loading, execute] = SubscriptionsModel.subscribeDoctor();
    const [, executeDoctors] = DoctorModel.getDoctors();
    const [doctor, setDoctor] = useState({ firstName: '', lastName: '', uid: '', value: '' });
    const history = useHistory();

    function handleSubmit(e: React.FormEvent<HTMLFormElement>, data: any) {
        data.doctorUid = doctor.value;
        if (!data.nextPaymentDue) {
            data.nextPaymentDue = undefined;
        }

        execute(data)
            .then(() => {
                modalAlert({
                    type: 'SUCCESS',
                    description: `Subscribe doctor to plan successfully`,
                    confirmButton: { action: () => history.push(`/subscriptions/details/${doctor.value}`) },
                });
            })
            .catch((err) => {
                if (err === 'NO_DEFAULT_CARD') modalAlert({ type: 'ERROR', description: `Doctor ${doctor.firstName} ${doctor.lastName} has no default card ` });
                else if (err === 'DEFAULT_CARD_ISSUE') modalAlert({ type: 'ERROR', description: `Cannot deduct the amount from doctor's default` });
            });
    }

    const loadOptions = async (inputValue: string) => {
        return executeDoctors({ search: inputValue, pageSize: 20 })
            .then((res) => res.doctors.map((result: any) => labelFormatter(result)))
            .catch((e) => console.log(' Error: ', e));
    };
    //select drug
    const onSelectDrug = (e: any) => {
        setDoctor(e);
    };

    return (
        <>
            <FormLayout
                inputs={[
                    {
                        name: 'name',
                        title: 'Package name',
                        type: formInputTypesEnum.SELECT,
                        options: ['Starter', 'Business', 'Premium', 'Ultimate'],
                        required: true,
                    },
                    { name: 'price', title: 'Price (EGP/Month)', type: formInputTypesEnum.NUMBER, pattern: Patterns.string(2, 50), required: true },
                    { name: 'recurrent', type: formInputTypesEnum.SELECT, required: true, options: recurrentOptions },
                    { name: 'paymentMethod', type: formInputTypesEnum.SELECT, required: true, options: paymentMethodOptions },
                    { name: 'nextPaymentDue', type: formInputTypesEnum.DATE, title: 'Next Payment date' },
                ]}
                headerComponent={
                    <div className={styles['searchBar-top']}>
                        <AsyncSelect
                            cacheOptions
                            isClearable
                            loadOptions={loadOptions}
                            onChange={(e: any) => onSelectDrug(e)}
                            placeholder='Search in doctors'
                        />
                    </div>
                }
                loading={loading}
                onSubmit={handleSubmit}
            />
        </>
    );
};
export default SubscribeDoctor;

const labelFormatter = (item: any) => {
    return { ...item, value: item.uid, label: `${item.firstName} ${item.lastName}` };
};
