import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { modalAlert, Btn } from '@curawella/admin-package';

import styles from './CreateAdmin.module.scss';
import FormLayout from '../../components/FormLayout/FormLayout';
import AdminsModal from '../../models/admins';

const CreateAdmin: React.FC = () => {
    const [form, setForm] = useState<Record<string, string>>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        position: '',
    });
    const [formErrors, setFormErrors] = useState<{
        [key: string]: { [value: string]: null | boolean | string };
    }>({
        firstName: {
            value: null,
            msg: null,
        },
        lastName: {
            value: null,
            msg: null,
        },
        email: {
            value: null,
            msg: null,
        },
        phone: {
            value: null,
            msg: null,
        },
        position: {
            value: null,
            msg: null,
        },
    });

    const [loading, executeCreateAdmin] = AdminsModal.createAdmin();

    const history = useHistory();
    const forms = [
        {
            name: 'First Name',
            type: 'text',
            value: form.firstName,
            name2: 'firstName',
            check: formErrors.firstName,
        },
        {
            name: 'Last Name',
            type: 'text',
            value: form.lastName,
            name2: 'lastName',
            check: formErrors.lastName,
        },
        {
            name: 'Email',
            type: 'text',
            value: form.email,
            name2: 'email',
            check: formErrors.email,
        },
        {
            name: 'Phone',
            type: 'text',
            value: form.phone,
            name2: 'phone',
            check: formErrors.phone,
        },
        {
            name: 'Position',
            type: 'select',
            value: form.position,
            name2: 'position',
            check: formErrors.position,
            options: [{ ADMIN: 'Admin' }, { SUPER_ADMIN: 'Super Admin' }],
        },
    ];

    const resetErrors = (): void => {
        setFormErrors({
            firstName: {
                value: null,
                msg: null,
            },
            lastName: {
                value: null,
                msg: null,
            },
            email: {
                value: null,
                msg: null,
            },
            phone: {
                value: null,
                msg: null,
            },
            position: {
                value: null,
                msg: null,
            },
        });
    };

    const validateInputs = (): boolean => {
        let result = true;
        const errs: any = {};
        // const arabic = /[\u0600-\u06FF]/;
        // const plainText = /[A-Za-z0-9_]/;
        const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

        // eslint-disable-next-line no-restricted-syntax
        for (const key in form) {
            if (Object.prototype.hasOwnProperty.call(form, key)) {
                const element: string = form[key];
                if (!element) {
                    result = false;
                    errs[key] = { value: false, msg: 'required' };
                } else if (key === 'email' && !emailRegex.test(element)) {
                    result = false;
                    errs[key] = { value: false, msg: 'wrong email format' };
                }
            }
        }
        console.log('errs: ', errs);

        setFormErrors(errs);
        return result;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let data = {};
        data = { ...form, [e.currentTarget.name]: e.currentTarget.value };
        console.log('data: ', data);
        setForm(data);
    };

    const navigateToAdmins = (): void => {
        history.push('/admins');
    };

    // =================SUBMIT ADD ADMIN=================
    const handleSubmit = async (e: React.FormEvent): Promise<any> => {
        e.preventDefault();
        console.log('form: ', form);
        resetErrors();

        if (!validateInputs()) {
            return;
        }
        const { firstName, lastName, email, phone, position } = form;
        await executeCreateAdmin({
            firstName,
            lastName,
            email,
            phone,
            position,
        })
            .then((res) => {
                console.log('create admin res: ', res);
                const { password } = res.results;
                modalAlert({
                    type: 'SUCCESS',
                    description: `password is ${password}`,
                    confirmButton: { action: navigateToAdmins },
                });
            })
            .catch((err) => console.log('create admin err: ', err));
    };

    return (
        <div className={styles['create-admin-container']}>
            <h3>Create Admin</h3>
            <Btn active handleClick={navigateToAdmins}>
                All Admins
            </Btn>
            <FormLayout inputs={forms} onChange={handleChange} loading={loading} onSubmit={handleSubmit} />
        </div>
    );
};

export default CreateAdmin;
