import {DataView, InputTypes, useFetch} from '@curawella/admin-package';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import styles from './CompaniesUsersDetails.module.scss';
import {Form} from 'react-bootstrap';
import {customApi, pharmaUsersTypes} from '../../../../constants';

const CompaniesUsersDetails = (): JSX.Element => {
    const [formData, setFormData] = useState([]);
    const [user, loading, error, execute] = useFetch();

    const {companyId, userUid} = useParams<{companyId: string; userUid: string}>();
    const history = useHistory();
    // const doctorUid: string = params.doctorUid;

    const handleInputs = useCallback((key: string, value: string) => {
        // const editableItems = ['firstName', 'lastName', 'isActive', 'phone', 'email', 'position', 'permission'];

        const arr: Record<string, string | number | Record<any, any>[]>[] = [];

        if (key === 'isActive') {
            arr.push({
                key: key,
                name2: key,
                label: key,
                value: value,
                type: InputTypes.SELECT,
                options: [{0: 'Deactivate'}, {1: 'Active'}]
            });
        } else if (key === 'position') {
            arr.push({
                key: key,
                name2: key,
                label: key,
                value: value,
                type: InputTypes.SELECT,
                options: pharmaUsersTypes
            });
        } else {
            arr.push({
                key: key,
                value: value,
                label: key,
                type: 'text'
            });
        }

        return arr;
    }, []);

    const permissionInputs = useCallback(() => {
        const arr: any[] = [];
        const premissionsKeys = ['AD_MANAGER', 'DOCTOR_AD', 'PATIENT_AD', 'DOCTOR_MEETING', 'STATISTICS'];
        premissionsKeys.forEach((key) => {
            arr.push({
                key: key,
                name2: key,
                label: key,
                value: user[key],
                type: InputTypes.SELECT,
                options: [{0: 'Disable'}, {1: 'Active'}]
            });
        });

        return arr;
    }, [user]);

    // =================HANDLE DATA FOR RENDERING=================
    const handleData = useCallback(() => {
        const arr: any = [];

        const editableItems = ['firstName', 'lastName', 'isActive', 'phone', 'email', 'position', 'permissions'];
        for (const key in user) {
            const value: string = user[key];
            if (!key.startsWith('permission')) {
                arr.push({
                    name: key,
                    title: key,
                    value,
                    type: 'text',
                    name2: key,
                    disabled: true,
                    editable: editableItems.includes(key) && true,
                    method: 'PUT',
                    endPoint: `admin/users/${companyId}/${userUid}/${key}`,
                    inputs: editableItems.includes(key) && handleInputs(key, value)
                });
            }
        }
        //ADD EXTRA ITEM FOR EDIT PERMISSIONS
        arr.push({
            name: 'permission',
            title: 'permission',
            value: null,
            type: 'text',
            name2: 'permission',
            disabled: true,
            editable: true,
            method: 'PUT',
            endPoint: `admin/users/${companyId}/${userUid}/permissions`,
            inputs: permissionInputs()
        });
        //ADD EXTRA ITEM FOR RESET PASSWORD
        arr.push({
            name: 'Reset Password',
            title: 'Reset Password',
            value: null,
            type: 'text',
            name2: 'Reset Password',
            disabled: true,
            editable: true,
            method: 'PUT',
            endPoint: `admin/users/${companyId}/${userUid}/resetPassword`,
            inputs: []
        });
        setFormData(arr);
    }, [companyId, handleInputs, permissionInputs, user, userUid]);

    // =================GETTING DOCTOR EARNINGS=================
    const getUserData = useCallback(() => {
        // admin/users/:companyId/:uid
        execute('get', `admin/users/${companyId}/${userUid}`, {}, customApi);
    }, [companyId, execute, userUid]);

    // =================AFTER MOUNTING=================
    useEffect(() => {
        getUserData();
    }, [getUserData]);

    // =================DOCTOR DATA RECEIVED=================
    useEffect(() => {
        if (Object.keys(user).length) {
            console.log('user: ', user);

            handleData();
        }
    }, [user, handleData]);

    // =================DOCTOR DATA ERROR=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    const onSuccess = useCallback(
        (data: Record<string, string>) => {
            console.log('data in project: ', data);
            const {password} = data;
            if (password) {
                alert(`your new password is ${password}`);
            }
            getUserData();
        },
        [getUserData]
    );

    return (
        <div className={styles['company-users-details']}>
            {loading && 'loading...'}
            <h1 className={styles['dr-name']}>
                {user.firstName} {user.lastName}
            </h1>
            <button onClick={() => history.push(`/companies/${companyId}/users`)}>Users</button>

            {formData && (
                <Form>
                    <DataView content={formData} customApi={customApi} onSuccess={onSuccess} />
                </Form>
            )}
        </div>
    );
};

export default CompaniesUsersDetails;
