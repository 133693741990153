import { useFetchType } from 'react-usefetch-models/build/types';
import { numericBoolean } from '../types/GeneralTypes';
import backendConfig from './backendConfig';

export type getDoctorsType = { approved?: numericBoolean; search?: string; pageNumber?: string; pageSize?: number };
export type getDoctorType = { doctorUid: string };
export type getDoctorEarningsType = { doctorUid: string; from: string; to: string };
export type editDoctorDetailsType = {
    doctorUid: string;
    type: string;
    firstName: string;
    lastName: string;
    firstNameAr: string;
    lastNameAr: string;
    avatar: string;
    phone: string;
    country: string;
    city: string;
    bd: string;
    gender: string;
    about: string;
    aboutSummary: string;
    level: string;
    specialtyNameEn: string;
    specialtyId: string;
    specialty: string;
    session: string;
    online: string;
    License: string;
    fakeId: string;
    licNum: string;
    NID: string;
    NIDBack: string;
    available: string;
    covid19: string;
    approved: string;
};
class DoctorModel {
    static getDoctors(): useFetchType<getDoctorsType> {
        return backendConfig.config.getData<getDoctorsType>({
            endPoint: 'admin/doctor/',
            queryDefault: { approved: '', search: '', pageNumber: '', pageSize: '' },
        });
    }

    static getDoctor(): useFetchType<getDoctorType> {
        const endPoint = 'admin/doctor/:doctorUid';

        return backendConfig.config.getData<getDoctorType>({ endPoint });
    }

    static getDoctorEarnings(): useFetchType<getDoctorEarningsType> {
        const endPoint = 'doctor/earnings/:doctorUid/:from/:to';

        return backendConfig.config.getData<getDoctorEarningsType>({ endPoint });
    }

    static getDoctorsGraph(): useFetchType<undefined> {
        const endPoint = 'admin/doctor/graphs';
        return backendConfig.config.getData({ endPoint });
    }

    static editDoctorDetails(): useFetchType<editDoctorDetailsType> {
        const endPoint = 'admin/doctor/:doctorUid/:type';
        const bodyDefault = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            phone: undefined,
            gender: undefined,
            bd: undefined,
            country: undefined,
            city: undefined,
            avatar: undefined,
            firstNameAr: undefined,
            lastNameAr: undefined,
            specialty: undefined,
            level: undefined,
            session: undefined,
            message: undefined,
            approved: undefined,
            available: undefined,

            about: undefined,
            aboutSummary: undefined,
            foreignOnline: undefined,
            covid19: undefined,
            online: undefined,
            followup: undefined,
            followUpWeeks: undefined,
            licNum: undefined,
            License: undefined,
            nidNum: undefined,
            NID: undefined,
            facebookLink: undefined,
            websiteLink: undefined,
            playStoreLink: undefined,
            appStoreLink: undefined,
            NIDBack: undefined,
            permissionClinicManagement: undefined,
            permissionMessaging: undefined,
            permissionVideoCall: undefined,
            permissionPersonalApp: undefined,
            permissionBooking: undefined,
            permissionPaymentOnsite: undefined,
            permissionPaymentOnline: undefined,
        };
        return backendConfig.config.updateData<editDoctorDetailsType>({ endPoint, bodyDefault });
    }
}

export default DoctorModel;
