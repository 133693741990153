import { formInputTypesEnum, FormLayout, Patterns, modalAlert } from '@curawella/admin-package';
import { useHistory } from 'react-router-dom';
import DrugModel, { dosageFormOptions, routesOptions } from '../../models/Drug';

const AddDrug = () => {
    const history = useHistory();
    const [loading, execute] = DrugModel.addDrug();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>, data: any) => {
        execute(data)
            .then(() => {
                modalAlert({
                    type: 'SUCCESS',
                    description: `Drug added successfully`,
                    confirmButton: { action: () => history.goBack() },
                });
            })
            .catch(() => {
                modalAlert({ type: 'ERROR', description: `Unable to add drug` });
            });
    };

    return (
        <div>
            <FormLayout
                inputs={[
                    { name: 'commercialName', pattern: Patterns.string(4, 45), required: true },
                    { name: 'scientificName', pattern: Patterns.string(4, 45), required: false },
                    { name: 'dosageForm', type: formInputTypesEnum.SELECT, options: dosageFormOptions },
                    { name: 'route', type: formInputTypesEnum.SELECT, options: routesOptions },
                    { name: 'dose', pattern: Patterns.string(4, 25), required: false },
                    { name: 'labelerName', pattern: Patterns.string(4, 65), required: false },
                    { name: 'type', pattern: Patterns.string(4, 45), required: false },
                ]}
                loading={loading}
                onSubmit={handleSubmit}
            />
        </div>
    );
};
export default AddDrug;
