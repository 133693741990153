import {FormInputs, useFetch} from '@curawella/admin-package';
import {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {customApi} from '../../../constants';
import styles from './CompaniesCreate.module.scss';

const CompaniesCreate = (): JSX.Element => {
    const [form, setForm] = useState({
        name: ''
    });
    const [formErrors, setFormErrors] = useState<{[key: string]: {[value: string]: null | boolean | string}}>({
        name: {
            value: null,
            msg: null
        }
    });
    const history = useHistory();
    const [data, loading, error, execute] = useFetch();

    const forms = [{name: 'Name', type: 'text', value: form.name, name2: 'name', check: formErrors.name}];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const data = {...form, [e.currentTarget.name]: e.currentTarget.value};
        setForm(data);
    };

    // =================NAVIGATE TO VIEW ALL COMPANIES=================
    const navigateToViewAll = useCallback(() => {
        history.push('/companies/view');
    }, [history]);

    // =================SUBMIT ADD COMPANY=================
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('form: ', form);
        setFormErrors({
            name: {
                value: null,
                msg: null
            }
        });

        if (!form.name) {
            setFormErrors({name: {value: false, msg: 'Required'}});
            return;
        }
        execute('post', 'admin/companies', form, customApi);
    };

    // =================ADD COMPANY DATA RECEIVED=================
    useEffect(() => {
        console.log('data: ', data);
        if (Object.keys(data).length) {
            setForm({name: ''});
            alert('added successfully');
            navigateToViewAll();
        }
    }, [data, navigateToViewAll]);

    // =================ADD NOTIFICATION DATA ERROR=================
    useEffect(() => {
        console.log('error: ', error);
    }, [error]);

    return (
        <div className={styles['companies-view-container']}>
            <h1>Companies</h1>
            <button onClick={() => history.push('/companies/view')}>View All</button>

            {loading && <p>loading...</p>}

            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'submit'} />
            </Form>
        </div>
    );
};

export default CompaniesCreate;
