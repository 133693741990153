import { DetailsView, Loading } from '@curawella/admin-package';
import moment from 'moment';
import { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ctx } from '../../contexts/userContext';
import EmailsModel from '../../models/emails';

const EmailDetails = () => {
    const { emailId } = useParams<{ emailId: string }>();
    const { user } = useContext(ctx);

    const [loading, execute, data] = EmailsModel.getEmailDetails();
    const { text, subject, createdAt, recipients } = data || {};

    /**
     * Get email details
     */
    const getUserDetails = useCallback((): void => {
        execute({ senderUid: user.uid, emailId });
    }, [emailId, execute, user.uid]);

    useEffect(() => getUserDetails(), [getUserDetails]);

    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            {/* data */}
            <DetailsView
                content={{
                    text: { value: text },
                    createdAt: { value: moment(createdAt).format('LLLL') },
                    subject: { value: subject },
                    name: {
                        title: 'Recipients',
                        type: 'LIST',
                        listItems: recipients && recipients.map((v: any) => ({ value: v.recipientEmail })),
                    },
                }}
            />
        </div>
    );
};

export default EmailDetails;
