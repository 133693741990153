// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vhSfsohCrCGrCVLK3eqK{display:flex;flex-direction:row;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/pages/ContactUsDetails/ContactUsDetails.module.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":["@import '../../assets/styles/colors.scss';\n\n.switch-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch-container": "vhSfsohCrCGrCVLK3eqK"
};
export default ___CSS_LOADER_EXPORT___;
