import { InputTypes, ModalComp, ModalTypes, TableComp, useFetch } from '@curawella/admin-package';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { customApi } from '../../../constants';
import styles from './CompaniesView.module.scss';
const tableData = {
    title: 'Companies',
    search: {
        show: true,
    },
    headers: [
        { title: 'Company Id', key: 'companyId' },
        { title: 'Name', key: 'name' },
        { title: 'Active', key: 'isActive' },
        { title: 'Actions', key: 'actions' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const CompaniesView = (): JSX.Element => {
    const [companies, loading, error, executeCompanies] = useFetch();

    const [renderCompanies, setRenderCompanies] = useState([]);

    const history = useHistory();

    // =================COMPANIES DATA=================

    const getCompanies = useCallback(() => {
        executeCompanies('get', 'admin/companies/', undefined, customApi);
    }, [executeCompanies]);

    useEffect(() => {
        getCompanies();
    }, [executeCompanies, getCompanies]);

    // =================COMPANIES DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('companies: ', companies);

        Object.entries(companies).forEach((company: any) => {
            const active = company[1].isActive ? 'Active' : 'Disabled';
            arr.push({
                ...company[1],
                isActive: active,
                actions: <Actions company={company[1]} getCompanies={getCompanies} />,
            });
        });
        setRenderCompanies(arr);
    }, [companies, getCompanies]);

    // =================COMPANIES DATA ERROR=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    return (
        <div className={styles['companies-view-container']}>
            <h1>Companies</h1>
            <button onClick={() => history.push('/companies/add')}>Add</button>

            {loading && <p>loading...</p>}

            {renderCompanies.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderCompanies}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    // rowAction={logRow}
                />
            )}
        </div>
    );
};

export default CompaniesView;

const Actions = ({ company, getCompanies }: Record<string, any>): JSX.Element => {
    // company: Record<string, any>
    const history = useHistory();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [form, setForm] = useState({
        name: '',
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: { [value: string]: null | boolean | string } }>({
        name: {
            value: null,
            msg: null,
        },
    });

    const [isActiveData, isActiveLoading, isActiveError, isActiveExecute] = useFetch();

    const forms = [
        {
            name: 'Name',
            type: InputTypes.TEXT,
            value: form.name,
            name2: 'name',
            check: formErrors.name,
            key: 'name',
            label: 'Name',
        },
    ];

    const toggleEditCompanyName = () => {
        setShowEditModal((prev) => !prev);
    };

    const onSuccess = useCallback(
        (data: any) => {
            console.log('received data: ', data);

            setShowEditModal(false);
            getCompanies();
        },
        [getCompanies],
    );

    const toggleIsActive = () => {
        console.log('company: ', company);

        const body = {
            isActive: company.isActive === 0 ? 1 : 0,
        };
        isActiveExecute('PUT', `admin/companies/${company.companyId}/isActive`, body, customApi);
    };

    useEffect(() => {
        if (Object.keys(isActiveData).length > 0) {
            console.log('isActiveData: ', isActiveData);
            getCompanies();
        }
    }, [isActiveData, getCompanies]);
    useEffect(() => {
        if (Object.keys(isActiveError).length > 0) {
            console.log('isActiveError: ', isActiveError);
        }
    }, [isActiveError]);

    const navigateToCompanyDetails = () => {
        history.push(`/companies/details/${company.companyId}`);
    };

    const navigateToCompanyUsers = () => {
        history.push(`/companies/${company.companyId}/users`);
    };
    return (
        <>
            <div className={styles['actions-btns-container']}>
                <button className={styles['action-btn']} onClick={toggleIsActive}>
                    {company.isActive ? 'Set Deactivate' : 'Set Activate'}
                </button>
                <button className={styles['action-btn']} onClick={toggleEditCompanyName}>
                    Change Name
                </button>
                <button className={styles['action-btn']} onClick={navigateToCompanyDetails}>
                    Details
                </button>
                <button className={styles['action-btn']} onClick={navigateToCompanyUsers}>
                    Users
                </button>
            </div>

            <ModalComp
                title='Edit Company'
                show={showEditModal}
                setShow={setShowEditModal}
                type={ModalTypes.Edit}
                method='PUT'
                endPoint={`admin/companies/${company.companyId}/name`}
                customApi={customApi}
                inputs={forms}
                onSuccess={onSuccess}
            />
        </>
    );
};
