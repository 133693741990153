import { InputTypes, ModalComp, ModalTypes, TableComp, useFetch } from '@curawella/admin-package';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customApi } from '../../../../constants';
import styles from './CompaniesSpecialties.module.scss';
const tableData = {
    title: 'Specialties',
    search: {
        show: true,
    },
    headers: [
        { title: 'Speciality Id', key: 'specialtyId' },
        { title: 'Name', key: 'name' },
        { title: 'Actions', key: 'actions' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const CompaniesSpecialties = ({ specialties, getCompany }: any): JSX.Element => {
    // const [companies, loading, error, executeCompanies] = useFetch();

    const [renderSpecialties, setRenderSpecialties] = useState([]);

    const history = useHistory();
    const { companyId } = useParams<{ companyId: string }>();

    // =================COMPANIES DATA=================

    // const getCompanies = useCallback(() => {
    //     executeCompanies('get', 'admin/companies/', undefined, customApi);
    // }, [executeCompanies]);

    // useEffect(() => {
    //     getCompanies();
    // }, [executeCompanies, getCompanies]);

    // =================COMPANIES DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('specialties: ', specialties);

        specialties.forEach((specialty: any) => {
            arr.push({
                ...specialty,
                actions: <Actions specialty={specialty} getCompany={getCompany} />,
            });
        });
        setRenderSpecialties(arr);
    }, [getCompany, specialties]);

    // =================COMPANIES DATA ERROR=================
    // useEffect(() => {
    //     if (Object.keys(error).length) {
    //         console.log('error: ', error);
    //     }
    // }, [error]);

    return (
        <div className={styles['specialties-container']}>
            {/* <h1>Companies</h1> */}

            {/* {loading && <p>loading...</p>} */}

            {renderSpecialties.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderSpecialties}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    // rowAction={logRow}
                />
            )}
            {/* /companies/details/:companyId/specialties/add */}
            <button onClick={() => history.push(`/companies/details/${companyId}/specialties/add`)}>Add Specialty</button>
        </div>
    );
};

export default React.memo(CompaniesSpecialties);

const Actions = ({ specialty, getCompany }: Record<string, any>): JSX.Element => {
    // company: Record<string, any>
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [form, setForm] = useState({
        name: '',
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: { [value: string]: null | boolean | string } }>({
        name: {
            value: null,
            msg: null,
        },
    });

    const [isActiveData, isActiveLoading, isActiveError, isActiveExecute] = useFetch();

    const { companyId } = useParams<{ companyId: string }>();

    const forms = [
        {
            name: 'Specialty Name',
            type: InputTypes.TEXT,
            value: form.name,
            name2: 'name',
            check: formErrors.name,
            key: 'name',
            label: 'Specialty Name',
        },
    ];

    const onSuccess = useCallback(
        (data: any) => {
            console.log('received data: ', data);

            setShowEditModal(false);
            getCompany();
        },
        [getCompany],
    );

    const toggleEditSpecialtyName = () => {
        setShowEditModal((prev) => !prev);
    };

    useEffect(() => {
        if (Object.keys(isActiveData).length > 0) {
            console.log('isActiveData: ', isActiveData);
            getCompany();
        }
    }, [isActiveData, getCompany]);
    useEffect(() => {
        if (Object.keys(isActiveError).length > 0) {
            console.log('isActiveError: ', isActiveError);
        }
    }, [isActiveError]);

    return (
        <>
            <div className={styles['actions-btns-container']}>
                <button className={styles['action-btn']} onClick={toggleEditSpecialtyName}>
                    Edit
                </button>
            </div>

            <ModalComp
                title='Edit Specialty'
                show={showEditModal}
                setShow={setShowEditModal}
                type={ModalTypes.Edit}
                method='PUT'
                endPoint={`admin/companies/${companyId}/specialty/${specialty.specialtyId}`}
                customApi={customApi}
                inputs={forms}
                onSuccess={onSuccess}
            />
        </>
    );
};
