import firebase from 'firebase/app';

import { DataViewItemTypes } from '@curawella/admin-package';

import FirebaseApp from '../config/FirebaseApp';
import { getBoolean } from '../helpers/format';

type imageObjectType = {
    title: string;
    type: DataViewItemTypes;
    value: { link: string }[];
    uploadImage: (c: File) => Promise<string>;
};
type toggleObjectType = {
    type: DataViewItemTypes;
    value: boolean;
    editable: boolean;
};

/**
 * Function return handled image data
 */
export function imageObject(title: string, value: string, doctorUid: string): imageObjectType {
    return {
        title: title.toUpperCase(),
        type: DataViewItemTypes.IMAGES,
        value: value ? [{ link: value }] : [],
        uploadImage: (c: File) => uploadImg(title, c, doctorUid),
    };
}

/**
 * Function return handled toggle data
 */
export function toggleObject(value: number, editable: boolean): toggleObjectType {
    return { type: DataViewItemTypes.TOGGLE, value: getBoolean(value), editable };
}

/**
 * Upload image to storage
 */
const uploadImg = (imageType: string, compressedImg: File, doctorUid: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const storageRef = getStorageRef(imageType, doctorUid);

        storageRef
            .put(compressedImg)
            .then((snapshot: any) => snapshot.ref.getDownloadURL())
            .then((downloadURL: any) => resolve(downloadURL))
            .catch((err: any) => reject(err));
    });
};

/**
 * Get storage ref
 */
function getStorageRef(imageType: string, doctorUid: string): firebase.storage.Reference {
    const storage = FirebaseApp.storage();
    const storageRef: { [key: string]: firebase.storage.Reference } = {
        avatar: storage.avatar(doctorUid),
        License: storage.license(doctorUid),
        NID: storage.nid(doctorUid),
        NIDBack: storage.nidBack(doctorUid),
    };

    const ref = storageRef[imageType];
    return ref;
}
