import { useCallback, useEffect, useState } from 'react';
import { DetailsView, formInputTypesEnum, Loading, modalAlert, Patterns } from '@curawella/admin-package';
import { GetCities } from '../../methods/GetCities';
import { GetCountries } from '../../methods/GetCountries';

import ImageHelpers from '../../methods/uploadImage';
import PatientModel, { getPatientType } from '../../models/Patients';

const genderOptions = [
    { value: '', title: 'Select' },
    { value: '1', title: 'Female' },
    { value: '0', title: 'Male' },
];

export type dataType = { patientUid: string };

type NameType = 'firstName' | 'lastName' | 'avatar' | 'phone' | 'country' | 'city' | 'bd' | 'gender' | 'fakeId';

const DataDetails = ({ patientUid }: dataType): JSX.Element => {
    const [cities, setCities] = useState<string[]>();
    const [loadingEdit, executeEdit] = PatientModel.editPatient();
    const [patientLoading, executePatient, patientData] = PatientModel.getPatient();
    const { firstName, lastName, avatar, country, phone, email, city, bd, gender, fakeId } = patientData || {};

    /**
     * Get patient data
     */
    const getPatientData = useCallback(() => {
        const data: getPatientType = { patientUid };
        executePatient(data);
    }, [executePatient, patientUid]);

    // Call getPatientData function to get patient data
    useEffect(() => {
        getPatientData();
    }, [getPatientData]);

    const onSubmit = async (e: any, data: Record<NameType, string | boolean>, key: string) => {
        const body: any = { uid: patientUid, type: key };

        for (const k in data) {
            const i = k as NameType;

            const element = data[i];

            if (i === 'avatar') {
                await ImageHelpers.uploadImg('avatar', element, patientUid).then((downloadURL) => {
                    body[i] = downloadURL;
                });
            } else {
                body[i] = element;
            }
        }

        executeEdit(body)
            .then(() => getPatientData())
            .catch((err: any) => {
                console.log('err', err);
                modalAlert({ type: 'ERROR', description: `failed to edit` });
            });
    };
    ///////////////////////////////// COUNTRY & CITY /////////////////////////////////////////////////
    /**
     * Get selected country cities
     */
    const getAllCities = useCallback((currentCountry: string) => {
        const newCities = Object.keys(GetCities(currentCountry));
        setCities(newCities);
    }, []);

    // Call getAllCities function to get selected country cities & update rendered county and city values
    useEffect(() => {
        if (patientUid) {
            getAllCities(country);
        }
    }, [country, patientUid, getAllCities]);

    const onChange = (e: any) => {
        const { value, name } = e.currentTarget;
        if (name === 'country') {
            getAllCities(value);
        }
    };

    if (loadingEdit || patientLoading) return <Loading />;
    return (
        <DetailsView
            content={{
                uid: { value: patientUid },
                name: {
                    value: `${firstName} ${lastName}`,
                    inputs: [
                        { name: 'firstName', defaultValue: firstName },
                        { name: 'lastName', defaultValue: lastName },
                    ],
                },
                avatar: { type: 'IMAGE', value: avatar },
                phone: { value: phone, inputs: [{ name: 'phone' }] },
                email: { value: email, inputs: [{ name: 'email' }] },
                countryCity: {
                    value: `${country}, ${city || '----'}`,
                    inputs: [
                        { name: 'country', type: formInputTypesEnum.SELECT, options: Object.keys(GetCountries()), defaultValue: country },
                        { name: 'city', type: formInputTypesEnum.SELECT, options: cities, defaultValue: city },
                    ],
                },
                bd: { value: bd, inputs: [{ name: 'bd', title: 'BirthDate', type: formInputTypesEnum.DATE }] },
                gender: {
                    value: gender === '1' ? 'Male' : 'Female',
                    inputs: [{ name: 'gender', type: formInputTypesEnum.SELECT, options: genderOptions, defaultValue: gender }],
                },

                fakeId: { value: fakeId },
            }}
            onChange={(e) => onChange(e)}
            onSubmit={onSubmit}
        />
    );
};
export default DataDetails;
