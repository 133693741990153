import { formInputTypesEnum, FormLayout, Patterns, CheckboxInput, modalAlert } from '@curawella/admin-package';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import { ctx } from '../../contexts/userContext';
import DoctorModel from '../../models/Doctor';
import EmailsModel, { sendEmailType } from '../../models/emails';

const SendEmail = () => {
    const { user } = useContext(ctx);
    const history = useHistory();
    const [checked, setChecked] = useState<string[]>([]);

    const [loading, execute] = EmailsModel.sendEmail();
    const [doctorLoading, executeDoctor, doctorsData] = DoctorModel.getDoctors();
    const doctors: { uid: string; firstName: string; lastName: string; email: string; phone: string }[] = doctorsData?.doctors || [];
    const options = doctors.map((doctor: any) => ({
        title: `${doctor.firstName} ${doctor.lastName} - ${doctor.email} - ${doctor.phone}`,
        value: doctor.email,
    }));
    options.unshift({ title: 'Select All', value: 'ALL' });

    useEffect(() => {
        executeDoctor({ approved: 1, pageSize: 1000 });
    }, [executeDoctor]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>, data: any) => {
        const body = data;

        body.senderUid = user.uid;
        body.recipients = checked;

        execute(body as sendEmailType)
            .then(() => {
                modalAlert({
                    type: 'SUCCESS',
                    description: `Email sent successfully`,
                    confirmButton: { action: () => history.goBack() },
                });
            })
            .catch(() => {
                modalAlert({ type: 'ERROR', description: `Unable to sent emails` });
            });
    };

    const handelSelectDoc = (checked: string[]) => {
        const found = checked.some((element: string) => element === 'ALL');

        if (found) {
            const arr = doctors.map((v) => v.email);
            setChecked(arr);
        } else {
            setChecked(checked);
        }
    };

    if (doctorLoading) {
        return <Loading />;
    }
    return (
        <div>
            <FormLayout
                inputs={[
                    { name: 'senderName', disabled: true, value: user.firstName + ' ' + user.lastName },
                    { name: 'senderEmail', disabled: true, value: user.email },
                    { name: 'subject', pattern: Patterns.string(2, 64), required: true },
                    { name: 'text', type: formInputTypesEnum.TEXTAREA, pattern: Patterns.string(10, 164), required: true },
                ]}
                loading={loading}
                onSubmit={handleSubmit}
                footerComponent={<CheckboxInput name='doctors' onChange={(checked) => handelSelectDoc(checked)} options={options} required checked={checked} />}
            />
        </div>
    );
};
export default SendEmail;
