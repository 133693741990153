import React from 'react';
import styles from './SwitchButton.module.scss';

type P = {
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
};

// eslint-disable-next-line react/prop-types
const Switch: React.FC<P> = ({checked, onChange}) => {
    return (
        <label className={styles['switch']}>
            <input type='checkbox' checked={checked} onChange={onChange} />
            <span className={`${styles['slider']} ${styles['round']}`} />
        </label>
    );
};

export default Switch;
