import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { TableComp } from '@curawella/admin-package';

import styles from './Patient.module.scss';
import PatientModel, { getPatientType } from '../../models/Patients';

import { getDate, getStatus } from '../../helpers/format';
import Loading from '../../components/Loading';

type appointments = {
    avatar: string;
    firstName: string;
    firstNameAr: string;
    lastName: string;
    lastNameAr: string;
    level: string;
    orderId: number;
    specialtyAr: string;
    specialtyEn: string;
    type: string;
};
type appointmentsData = appointments & {
    appointmentStatus: number;
    appointmentType: string;
    callId: string;
    clinicId: number | null;
    clinicName: string | null;
    timestamp: string;
};
type servicesData = appointments & {
    date: string;
    serviceAppointmentId: number;
    serviceNameAr: string;
    serviceNameEn: string;
    servicePrice: number;
};

const appointmentsTableData = {
    title: 'Appointments',
    search: { show: true },
    showDataLength: true,
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    headers: [
        { title: 'Appointment Time', key: 'timestamp' },
        { title: 'Dr. Name', key: 'name' },
        { title: 'Status', key: 'appointmentStatus' },
        { title: 'Clinic Name', key: 'clinicName' },
        { title: 'Order Id', key: 'orderId' },
        { title: 'Call Id', key: 'callId' },
    ],
};
const servicesTableData = {
    title: 'Services',
    search: { show: true },
    showDataLength: true,
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    headers: [
        { title: 'Date', key: 'date' },
        { title: 'Dr. Name', key: 'name' },
        { title: 'English Name', key: 'serviceNameEn' },
        { title: 'Price', key: 'servicePrice' },
        { title: 'Order Id', key: 'orderId' },
    ],
};

const Appointments = (): JSX.Element => {
    const params: { patientUid: string } = useParams();
    const patientUid: string = params.patientUid;

    const [loading, execute, appointments] = PatientModel.getAppointments();

    const [renderAppointments, setRenderAppointments] = useState<any[]>([]);
    const [renderServices, setRenderServices] = useState<any[]>([]);

    /**
     * Get appointments
     */
    const getAppointments = useCallback(() => {
        const data: getPatientType = { patientUid };

        execute(data);
    }, [patientUid, execute]);

    /**
     * Handel appointments data for rendering
     */
    const formatAppointments = (data: appointmentsData[]) => {
        console.log('Appointments:', data);
        data = data.filter((v) => v.type === 'APPOINTMENT');

        const arr = data
            .map((item: appointmentsData) => {
                return {
                    timestamp: moment(item.timestamp, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY hh:mm a'),
                    name: `${item.firstName} ${item.lastName}`,
                    clinicName: item.clinicName || 'ONLINE',
                    appointmentStatus: getStatus(item.appointmentStatus),
                    appointmentType: item.appointmentType,
                    callId: item.callId,
                    orderId: item.orderId,
                    clinicId: item.clinicId,
                };
            })
            .sort((a, b) => {
                const first = moment(b.timestamp, 'DD-MM-YYYY hh:mm a').unix();
                const second = moment(a.timestamp, 'DD-MM-YYYY hh:mm a').unix();
                return first - second;
            });

        setRenderAppointments(arr);
    };

    /**
     * Handel services data for rendering
     */
    const formatServices = (data: servicesData[]) => {
        console.log('Services: ', data);
        data = data.filter((v) => v.type === 'SERVICE');

        const arr = data
            .map((item: servicesData) => {
                return {
                    name: `${item.firstName} ${item.lastName}`,
                    date: getDate(item.date),
                    servicePrice: item.servicePrice,
                    serviceNameEn: item.serviceNameEn,
                    serviceNameAr: item.serviceNameAr,
                    serviceAppointmentId: item.serviceAppointmentId,
                    orderId: item.orderId,
                };
            })
            .sort((a, b) => {
                const first = moment(b.date, 'DD-MM-YYYY hh:mm a').unix();
                const second = moment(a.date, 'DD-MM-YYYY hh:mm a').unix();
                return first - second;
            });

        setRenderServices(arr);
    };

    // Call getAppointments function to get appointments
    useEffect(() => {
        getAppointments();
    }, [getAppointments]);

    // Call formatAppointments function to handle appointments
    useEffect(() => {
        if (appointments) {
            if (appointments) {
                const { history, upcoming } = appointments;
                const { upcomingAppointments, upcomingServices } = upcoming;
                const appointmentsArr = [...history, ...upcomingAppointments];
                const servicesArr = [...history, ...upcomingServices];

                formatAppointments(appointmentsArr);
                formatServices(servicesArr);
            }
        }
    }, [appointments]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles['appointment-container']}>
            {/* appointments data */}
            {renderAppointments.length > 0 ? (
                <div className={styles['table-container']}>
                    <TableComp
                        title={appointmentsTableData.title}
                        headers={appointmentsTableData.headers}
                        data={renderAppointments}
                        pagination={appointmentsTableData.pagination}
                        showDataLength={appointmentsTableData.showDataLength}
                    />
                </div>
            ) : (
                // no appointments message
                <p className={styles['no-data']}>There is no appointments</p>
            )}

            {/* services data */}
            {renderServices.length > 0 ? (
                <div className={styles['table-container']}>
                    <TableComp
                        title={servicesTableData.title}
                        headers={servicesTableData.headers}
                        data={renderServices}
                        pagination={servicesTableData.pagination}
                        showDataLength={servicesTableData.showDataLength}
                    />
                </div>
            ) : (
                // no services message
                <p className={styles['no-data']}>There is no services</p>
            )}
        </div>
    );
};

export default Appointments;
