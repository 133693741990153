import { BackendCall } from 'react-usefetch-models';
import { api } from '../constants';

//  API Backend base url
const baseApiUrl = api;

class Backend {
    config!: BackendCall;

    constructor() {
        this.initializeBackend(undefined);
    }

    updateAuthToken(authToken: string): void {
        this.initializeBackend(authToken);
    }

    private initializeBackend(authToken?: string): void {
        // Default Headers containing headers to be set by default to any request unless it overwritten
        const defaultHeaders: Record<string, string> = { 'Content-Type': 'application/json' };

        if (authToken) {
            defaultHeaders['auth-token'] = authToken;
        }

        if (!baseApiUrl) {
            throw new Error('Backend API NOT FOUND');
        }

        /**
         * Function to be executed after the success and do whatever you want
         * @param {any} res
         */
        async function defaultSuccessFunction(res: any): Promise<any> {
            return new Promise((resolve, reject) => {
                let d;
                if (res.success) {
                    console.log('success: ', res);
                    if (res.data) {
                        d = res.data;
                    } else if (res.results) {
                        d = res.results;
                    } else {
                        d = res;
                    }
                    resolve(d);
                } else {
                    console.log('failed: ', res.code);
                    reject(res.code);
                    // throw new Error(code);
                }
            });
        }

        /**
         * Function to be executed after the catching the error and do whatever you want
         * @param {any} err the error as a first and only parameter
         * @return {any} Whatever u want to be in the rejected state
         */
        async function defaultErrorFunction(err: any): Promise<any> {
            return err;
        }

        this.config = new BackendCall(baseApiUrl, defaultHeaders, defaultSuccessFunction, defaultErrorFunction);
    }
}

export default new Backend();
