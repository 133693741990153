import { useContext, useState } from 'react';
import LoginModel from '../../models/login';
import backendConfig from '../../models/backendConfig';
import { ctx } from '../../contexts/userContext';
import { Login, Patterns, updateToken } from '@curawella/admin-package';

const LoginPage = (): JSX.Element => {
    const [loading, executeLogin] = LoginModel.loginUser();
    const [form, setForm] = useState<Record<string, string>>({ credential: '', password: '' });
    const [err, setErr] = useState<string>('');
    const { setUser } = useContext(ctx);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.currentTarget;

        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!e.currentTarget.checkValidity()) {
            setErr('wrong credentials');
            return;
        }
        const { credential, password } = form;
        executeLogin({ credential, password })
            .then((res) => {
                const { accessToken, profile } = res;
                backendConfig.updateAuthToken(accessToken);
                updateToken(accessToken);

                setUser(profile);
            })
            .catch((err) => {
                setErr('wrong credentials');
            });
    };

    return (
        <Login
            button={loading ? 'Loading...' : 'Login'}
            onChange={handleChange}
            onSubmit={handleSubmit}
            username={{ name: 'credential', title: 'Email', value: form.nid, pattern: Patterns.email }}
            password={{ name: 'password', title: 'Password', value: form.password }}
            errorMessage={err}
            loading={loading}
            logoSrc='/logo192.png'
        />
    );
};

export default LoginPage;
