import {FormInputs, InputTypes, useFetch} from '@curawella/admin-package';
import {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {customApi} from '../../../../../constants';
import styles from './AddClinic.module.scss';

const AddClinic = (): JSX.Element => {
    const [specialties, setSpecialties] = useState<Record<string, string | number>[]>();
    const [form, setForm] = useState({
        doctorUid: '',
        doctorName: '',
        specialtyId: ''
    });

    const [formErrors, setFormErrors] = useState<{[key: string]: {[value: string]: null | boolean | string}}>({
        doctorUid: {
            value: null,
            msg: null
        },
        doctorName: {
            value: null,
            msg: null
        },
        specialtyId: {
            value: null,
            msg: null
        }
    });
    const history = useHistory();

    const {companyId} = useParams<{companyId: string}>();
    const [data, loading, error, execute] = useFetch();
    const [companyData, companyLoading, companyError, companyExecute] = useFetch();
    const forms = [
        {
            name: 'Doctor UID',
            type: InputTypes.TEXT,
            value: form.doctorUid,
            name2: 'doctorUid',
            check: formErrors.doctorUid,
            key: 'doctorUid',
            label: 'Doctor UID'
        },
        {
            name: 'Doctor Name',
            type: InputTypes.TEXT,
            value: form.doctorName,
            name2: 'doctorName',
            check: formErrors.doctorName,
            key: 'doctorName',
            label: 'Doctor Name'
        },
        {
            name: 'Specialty',
            type: InputTypes.SELECT,
            options: specialties,
            // options: [{VOIP: 'voip'}, {NOTIFICATION: 'Notification'}],
            // 0: [{specialtyId: 1, name: 'Specialty test'}]
            value: form.specialtyId,
            name2: 'specialtyId',
            check: formErrors.specialtyId,
            key: 'specialtyId',
            label: 'Specialty'
        }
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const data = {...form, [e.currentTarget.name]: e.currentTarget.value};
        setForm(data);
    };

    // =================NAVIGATE TO VIEW ALL COMPANIES=================
    const navigateToViewCompanyDetails = useCallback(() => {
        history.push(`/companies/details/${companyId}`);
        // /companies/details/1
    }, [companyId, history]);

    // =================SUBMIT ADD COMPANY=================
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('form: ', form);
        setFormErrors({
            doctorUid: {
                value: null,
                msg: null
            },
            doctorName: {
                value: null,
                msg: null
            },
            specialtyId: {
                value: null,
                msg: null
            }
        });

        if (!form.doctorUid) {
            setFormErrors({doctorUid: {value: false, msg: 'Required'}});
            return;
        }

        if (!form.doctorName) {
            setFormErrors({doctorName: {value: false, msg: 'Required'}});
            return;
        }
        if (!form.specialtyId) {
            setFormErrors({specialtyId: {value: false, msg: 'Required'}});
            return;
        }
        // /admin/companies/:specialtyId/clinic
        execute('post', `admin/companies/${form.specialtyId}/clinic`, form, customApi);
    };

    // =================GET COMPANY DETAILS=================

    const getCompany = useCallback(() => {
        companyExecute('GET', `admin/companies/${companyId}`, undefined, customApi);
    }, [companyExecute, companyId]);

    useEffect(() => {
        getCompany();
    }, [getCompany]);

    // =================DATA=================

    const handleSpecialties = useCallback(() => {
        const {specialties} = companyData;
        const arr: Record<string, string | number>[] = [];
        // options: [{VOIP: 'voip'}, {NOTIFICATION: 'Notification'}],
        // 0: [{specialtyId: 1, name: 'Specialty test'}]
        specialties.forEach((specialty: Record<string, string | number>) => {
            console.log('Object.values(specialty): ', Object.values(specialty));

            arr.push({
                [Object.values(specialty)[0]]: Object.values(specialty)[1]
            });
        });

        console.log('arr: ', arr);
        setSpecialties(arr);
    }, [companyData]);

    useEffect(() => {
        if (Object.keys(data).length) {
            setForm({doctorUid: '', doctorName: '', specialtyId: ''});
            alert('added successfully');
            navigateToViewCompanyDetails();
        }
    }, [data, navigateToViewCompanyDetails]);

    useEffect(() => {
        if (Object.keys(companyData).length) {
            console.log('companyData: ', companyData);
            handleSpecialties();
        }
    }, [companyData, handleSpecialties]);

    // =================ERRORS=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    useEffect(() => {
        if (Object.keys(companyError).length) {
            console.log('companyError: ', companyError);
        }
    }, [companyError]);

    console.log('form: ', form);

    return (
        <div className={styles['companies-view-container']}>
            <h1>Add Clinic</h1>
            <button onClick={() => history.push('/companies/view')}>View All</button>

            {loading && <p>loading...</p>}

            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'submit'} />
            </Form>
        </div>
    );
};

export default AddClinic;
