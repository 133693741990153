import { useFetchType } from 'react-usefetch-models/build/types';
import Backend from './backendConfig';

export type sendEmailType = { senderUid: string; senderName: string; senderEmail: string; subject: string; text: string; recipients: [string] };
export type getEmailsType = { senderUid: string; pageNumber?: string; pageSize?: string };
export type getEmailDetailsType = { senderUid: string; emailId: string };

class EmailsModel {
    // ===================================================Send Email=====================================================
    static sendEmail(): useFetchType {
        const endPoint = 'admin/emails/:senderUid';
        const bodyDefault = { recipients: [''], senderEmail: '', senderName: '', subject: '', text: '' };
        return Backend.config.postData({ endPoint, bodyDefault });
    }

    static getEmails(): useFetchType<getEmailsType> {
        const endPoint = 'admin/emails/:senderUid';
        const queryDefault = { pageNumber: '', pageSize: '' };
        return Backend.config.getData({ endPoint, queryDefault });
    }
    // ===================================================GET Doctors=====================================================

    static getDoctorsGraph(): useFetchType<undefined> {
        const endPoint = 'admin/doctor/graphs';
        return Backend.config.getData({ endPoint });
    }

    static getEmailDetails() {
        const endPoint = 'admin/emails/:senderUid/:emailId';
        return Backend.config.getData<getEmailDetailsType>({ endPoint });
    }
}

export default EmailsModel;
