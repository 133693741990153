import imageCompression from 'browser-image-compression';

import firebase from 'firebase/app';
import FirebaseApp from '../config/FirebaseApp';

class ImageHelpers {
    /**
     *
     * @param {File} image
     * @return {url: string}
     */
    static async uploadImg(type: string, image: any, uid: string) {
        try {
            return ImageHelpers.compressImages(image)
                .then((compressedImg) => {
                    console.log('compressedImg', compressedImg);
                    return ImageHelpers.upload(type, compressedImg, uid);
                })
                .then((downloadURL) => {
                    console.log('downloadURL', downloadURL);
                    return downloadURL;
                });
        } catch (e) {
            throw e;
        }
    }

    /**
     *
     * @param {File} element
     * @return {Promise<File>}
     */
    static async compressImages(element: any) {
        const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };
        return imageCompression(element, options);
    }

    /**
     *
     * @param {File} compressedImg
     * @return {Promise<string>}
     */
    static async upload(imageType: string, compressedImg: any, uid: string) {
        return new Promise(async (resolve, reject) => {
            const storageRef = ImageHelpers.getStorageRef(imageType, uid);

            // Upload the file
            storageRef
                .put(compressedImg)
                .then(async (snapshot: any) => {
                    const downloadURL = await snapshot.ref.getDownloadURL();
                    console.log('downloadURL ', downloadURL);
                    resolve(downloadURL);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     *
     * @param {{nameAr:string, nameEn:string, detailsAr:string, detailsEn:string, price:string, images: {url: string, descriptionAr: string, descriptionEn: string}[]}} service
     * @return {{valid: boolean, errors: {nameAr:string, nameEn:string, detailsAr:string, detailsEn:string, price:string, images: {url: string, descriptionAr: string, descriptionEn: string}[]}}}
     */

    /**
     * Get storage ref
     */
    static getStorageRef(imageType: string, uid: string): firebase.storage.Reference {
        const storage = FirebaseApp.storage();
        const storageRef: { [key: string]: firebase.storage.Reference } = {
            avatar: storage.avatar(uid),
            License: storage.license(uid),
            NID: storage.nid(uid),
            NIDBack: storage.nidBack(uid),
        };

        const ref = storageRef[imageType];
        return ref;
    }
}

export default ImageHelpers;
