import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyAHPrWPDgtdfg2ihSxwQ6PLYAvS_zd19N8',
    authDomain: 'curawella-development.firebaseapp.com',
    databaseURL: 'https://curawella-development.firebaseio.com',
    projectId: 'curawella-development',
    storageBucket: 'curawella-development.appspot.com',
    messagingSenderId: '434693192696',
    appId: '1:434693192696:web:c60e20b4f407f366',
};

class FirebaseConfigs {
    firebaseApp;

    constructor() {
        if (!firebase.apps.length) {
            this.firebaseApp = firebase.initializeApp(firebaseConfig);
        } else {
            this.firebaseApp = firebase.app();
        }
    }
    storage() {
        return {
            ...this.firebaseApp.storage(),
            avatar: (uid: string) => this.firebaseApp.storage().ref(`/Avatar/${uid}`),
            nid: (uid: string) => this.firebaseApp.storage().ref(`/NID/${uid}`),
            nidBack: (uid: string) => this.firebaseApp.storage().ref(`/NIDback/${uid}`),
            license: (uid: string) => this.firebaseApp.storage().ref(`/License/${uid}`),
        };
    }

    auth() {
        return this.firebaseApp.auth();
    }

    analytics() {
        return this.firebaseApp.analytics();
    }
}

export default new FirebaseConfigs();
