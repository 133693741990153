// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oM7vSk1dp4SlNrq2or3A{min-height:90vh;text-align:center;display:flex;flex-direction:column}.oM7vSk1dp4SlNrq2or3A form{display:flex;flex-direction:column}.oM7vSk1dp4SlNrq2or3A button,.oM7vSk1dp4SlNrq2or3A input[type=submit]{min-width:200px;padding:.5rem 1rem;border-radius:2rem;font-size:1.5rem;color:#fff;border:none;margin:1rem;color:#fff;background-color:#01b1be;align-self:flex-end}.oM7vSk1dp4SlNrq2or3A input[type=submit]{align-self:flex-start;font-size:1.25rem;margin:0}", "",{"version":3,"sources":["webpack://./src/pages/companies/companiesCreate/CompaniesCreate.module.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA,sBACI,eAAA,CACA,iBAAA,CAEA,YAAA,CACA,qBAAA,CAEA,2BACI,YAAA,CACA,qBAAA,CAGJ,sEAEI,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAEA,UAAA,CACA,wBCZE,CDaF,mBAAA,CAGJ,yCACI,qBAAA,CACA,iBAAA,CACA,QAAA","sourcesContent":["@import '../../../assets/styles/colors.scss';\n\n.companies-view-container {\n    min-height: 90vh;\n    text-align: center;\n\n    display: flex;\n    flex-direction: column;\n\n    form {\n        display: flex;\n        flex-direction: column;\n    }\n\n    button,\n    input[type='submit'] {\n        min-width: 200px;\n        padding: 0.5rem 1rem;\n        border-radius: 2rem;\n        font-size: 1.5rem;\n        color: white;\n        border: none;\n        margin: 1rem;\n\n        color: white;\n        background-color: $primary;\n        align-self: flex-end;\n    }\n\n    input[type='submit'] {\n        align-self: flex-start;\n        font-size: 1.25rem;\n        margin: 0;\n    }\n}\n","// $primary: #31c2e1;\n// $secondary: #44a8de;\n\n// $gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);\n\n// $bg: #f8fefe;\n\n// $border: #f0fbfd;\n\n$text-dark: #434343;\n\n// $text-light: #808080;\n\n$primary: #01b1be;\n$secondary: #029ca8;\n$input-shadow: #01b1be25;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companies-view-container": "oM7vSk1dp4SlNrq2or3A"
};
export default ___CSS_LOADER_EXPORT___;
