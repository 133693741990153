import { useFetchType } from 'react-usefetch-models/build/types';
import backendConfig from './backendConfig';

class SpecialtyModel {
    static getAllSpecialties(): useFetchType<undefined> {
        const endPoint = 'admin/specialties';

        return backendConfig.config.getData<undefined>({ endPoint });
    }
}

export default SpecialtyModel;
