import {useFetchType} from 'react-usefetch-models/build/types';
import PharmaBackend from './pharmaBackendConfig';

export type getApprovementDetailsType = {companyId: string; elementType: string; elementId: string};
export type createApprovementType = {elementType: string; elementId: string; isApproved: boolean; approvedBy: string};

class ApprovementModal {
    // ===================================================GET Approvement=====================================================
    static getApprovement(): useFetchType {
        const endPoint = 'admin/approves/';
        return PharmaBackend.config.getData({endPoint});
    }

    // ===================================================CREATE Approvement=====================================================
    static createApprovement(): useFetchType<createApprovementType> {
        const endPoint = 'admin/approves/:elementType/:elementId';
        // admin/approves/:elementType/:elementId
        const bodyDefault = {isApproved: true, approvedBy: ''};
        return PharmaBackend.config.postData<createApprovementType>({endPoint, bodyDefault});
    }

    // ===================================================GET Approvement DETAILS=====================================================
    static getApprovementDetails(): useFetchType<getApprovementDetailsType> {
        const endPoint = 'admin/approves/:companyId/:elementType/:elementId';
        return PharmaBackend.config.getData<getApprovementDetailsType>({endPoint});
    }
}

export default ApprovementModal;
