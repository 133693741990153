import {useFetch} from '@curawella/admin-package';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {customApi} from '../../../constants';
import CompaniesClinics from './companiesClinics/CompaniesClinics';
import styles from './CompaniesDetails.module.scss';
import CompaniesSpecialties from './companiesSpecialities/CompaniesSpecialties';

const CompaniesDetails = (): JSX.Element => {
    const {companyId} = useParams<{companyId: string}>();

    const [company, loading, error, execute] = useFetch();
    const history = useHistory();
    // =================GET COMPANY DETAILS=================

    const getCompany = useCallback(() => {
        execute('GET', `admin/companies/${companyId}`, undefined, customApi);
    }, [companyId, execute]);

    useEffect(() => {
        getCompany();
    }, [getCompany]);

    // =================NAVIGATE TO VIEW ALL COMPANIES=================
    // const navigateToViewAll = useCallback(() => {
    //     history.push('/companies/view');
    // }, [history]);

    // =================SUBMIT ADD COMPANY=================
    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     console.log('form: ', form);
    //     setFormErrors({
    //         name: {
    //             value: null,
    //             msg: null
    //         }
    //     });

    //     setForm({name: ''});
    //     if (!form.name) {
    //         setFormErrors({name: {value: false, msg: 'Required'}});
    //         return;
    //     }
    //     execute('post', 'admin/companies', form, customApi);
    // };

    // ================= COMPANY DATA RECEIVED=================
    useEffect(() => {
        console.log('company: ', company);
        if (Object.keys(company).length) {
            console.log('company data: ', company);
        }
    }, [company]);

    // ================= COMPANY DATA ERROR=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    return (
        <div className={styles['companies-view-container']}>
            <h1>{company.name} Details</h1>

            {loading && <p>loading...</p>}
            <button onClick={() => history.push('/companies/view')}>Companies</button>

            {company.clinics && (
                <CompaniesClinics clinics={company.clinics} getCompany={getCompany} specialties={company.specialties} />
            )}
            {company.specialties && <CompaniesSpecialties specialties={company.specialties} getCompany={getCompany} />}

            {/* 
            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'Change Name'} />
            </Form> */}
        </div>
    );
};

export default CompaniesDetails;
