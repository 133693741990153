// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ovApaoM6xmpMe8ijVU5P button{min-width:200px;padding:.5rem 1rem;border-radius:2rem;font-size:1.5rem;color:#fff;border:none;margin:1rem;color:#000;background-color:#fafafa}.ovApaoM6xmpMe8ijVU5P button.vuFpLFZGQ8M3jqM6m6_1{color:#fff;background-color:#01b1be}", "",{"version":3,"sources":["webpack://./src/pages/contactUs/ContactUs.module.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEI,6BACI,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,UAAA,CACA,wBAAA,CACA,kDACI,UAAA,CACA,wBCDF","sourcesContent":["@import '../../assets/styles/colors.scss';\n.contactus-container {\n    button {\n        min-width: 200px;\n        padding: 0.5rem 1rem;\n        border-radius: 2rem;\n        font-size: 1.5rem;\n        color: white;\n        border: none;\n        margin: 1rem;\n        color: black;\n        background-color: #fafafa;\n        &.active {\n            color: white;\n            background-color: $primary;\n        }\n    }\n}\n","// $primary: #31c2e1;\n// $secondary: #44a8de;\n\n// $gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);\n\n// $bg: #f8fefe;\n\n// $border: #f0fbfd;\n\n$text-dark: #434343;\n\n// $text-light: #808080;\n\n$primary: #01b1be;\n$secondary: #029ca8;\n$input-shadow: #01b1be25;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactus-container": "ovApaoM6xmpMe8ijVU5P",
	"active": "vuFpLFZGQ8M3jqM6m6_1"
};
export default ___CSS_LOADER_EXPORT___;
