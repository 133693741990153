import {FormInputs} from '@curawella/admin-package';
import {Form} from 'react-bootstrap';
import styles from './FormLayout.module.scss';

type formLayoutType = {
    inputs: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent) => void;
    loading: boolean;
    children?: React.ReactNode;
};
const FormLayout = ({inputs, onSubmit, onChange, loading, children}: formLayoutType): JSX.Element => (
    <Form onSubmit={onSubmit} className={styles.form}>
        {children || ''}
        <FormInputs inputs={inputs} handleChange={onChange} />
        <button aria-label='submit' type='submit' disabled={loading && true} className={styles['submit-btn']}>
            {loading ? 'loading...' : 'Submit'}
        </button>
    </Form>
);

export default FormLayout;
