import {DataView, DataViewItem, useFetch} from '@curawella/admin-package';
import {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {customDataType, HandleData} from '../../methods/HandleData';
import SwitchButton from '../../components/SwitchButton/SwitchButton';
import styles from './ContactUsDetails.module.scss';

type PropsType = RouteComponentProps & {
    row: {
        appId: string;
        appName: string;
        body: string;
        id: number;
        isPublic: string;
        phone: string;
        subject: string;
        timestamp: string;
        userName: string;
    };
};

const ContactUsDetails = (p: PropsType): JSX.Element => {
    const rowData: {[key: string]: any} = p.location.state as {[key: string]: any};
    const editable = false;

    const [data, loading, error, execute] = useFetch();
    const [formData, setFormData] = useState<DataViewItem[]>([]);

    const togglePendingStatus = () => {
        console.log('rowData: ', rowData);

        execute('put', 'admin/contactus', {
            id: rowData.id,
            isPublic: Boolean(rowData.isPublic)
        });
        history.back();
    };

    const handleData = useCallback(() => {
        const custom: {[k: string]: customDataType} = {
            id: {editable},
            isPublic: {editable},
            appId: {editable},
            appName: {editable},
            userName: {editable},
            phone: {editable},
            timestamp: {editable},
            subject: {editable},
            body: {editable}
        };

        const handledData = HandleData(rowData, custom);

        setFormData(handledData);
    }, [editable, rowData]);

    useEffect(() => {
        handleData();
    }, [handleData]);

    return (
        <div>
            {loading && <p>loading...</p>}

            <div className={styles['switch-container']}>
                <h3>Resolved</h3>
                <SwitchButton
                    checked={!rowData.isPending}
                    onChange={() => (rowData.isPending ? togglePendingStatus() : {})}
                />
            </div>

            {formData && <DataView content={formData} />}
        </div>
    );
};

export default withRouter(ContactUsDetails);
