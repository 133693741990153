// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fsy5UIfJySTYArmNEXiQ .Y8HvJD_e4tDBTrbRQ5gV{display:flex;flex-wrap:wrap;justify-content:flex-end;margin-bottom:2rem}.Fsy5UIfJySTYArmNEXiQ .Y8HvJD_e4tDBTrbRQ5gV .aBeHwYCNyjyoMII5hqWv{margin:0 1rem}.Fsy5UIfJySTYArmNEXiQ .Y8HvJD_e4tDBTrbRQ5gV .aBeHwYCNyjyoMII5hqWv:last-child{margin-right:0}.Fsy5UIfJySTYArmNEXiQ .Y8HvJD_e4tDBTrbRQ5gV .aBeHwYCNyjyoMII5hqWv label{margin-right:.5rem}.Fsy5UIfJySTYArmNEXiQ .Y8HvJD_e4tDBTrbRQ5gV .aBeHwYCNyjyoMII5hqWv input{padding:.5rem 1rem;border-radius:2rem;border:none;background-color:#fafafa}", "",{"version":3,"sources":["webpack://./src/pages/Earnings/Earnings.module.scss"],"names":[],"mappings":"AACI,4CAEI,YAAA,CACA,cAAA,CACA,wBAAA,CACA,kBAAA,CACA,kEAEI,aAAA,CACA,6EACI,cAAA,CAEJ,wEACI,kBAAA,CAEJ,wEACI,kBAAA,CACA,kBAAA,CACA,WAAA,CACA,wBAAA","sourcesContent":[".earnings-container {\n    .inputs-container {\n        // border: solid;\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: flex-end;\n        margin-bottom: 2rem;\n        .input-container {\n            // border: solid;\n            margin: 0 1rem;\n            &:last-child {\n                margin-right: 0;\n            }\n            label {\n                margin-right: 0.5rem;\n            }\n            input {\n                padding: 0.5rem 1rem;\n                border-radius: 2rem;\n                border: none;\n                background-color: #fafafa;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"earnings-container": "Fsy5UIfJySTYArmNEXiQ",
	"inputs-container": "Y8HvJD_e4tDBTrbRQ5gV",
	"input-container": "aBeHwYCNyjyoMII5hqWv"
};
export default ___CSS_LOADER_EXPORT___;
