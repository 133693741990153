export const firebaseConfig = {
    apiKey: 'AIzaSyBNNdq9XGEL3Fwe5bE-TfiwLyc71xzL_qg',
    authDomain: 'el3eyada-cdb0f.firebaseapp.com',
    databaseURL: 'https://el3eyada-cdb0f.firebaseio.com',
    projectId: 'el3eyada-cdb0f',
    storageBucket: 'el3eyada-cdb0f.appspot.com',
    messagingSenderId: '801454395931',
    appId: '1:801454395931:web:c8e487ca0a684d1b935ebe',
};

export const appId = '9b0db56b-f76d-4253-bb9d-d2489c994a39';

export const gender: Record<number, string> = { 0: 'Female', 1: 'Male' };
export const adminPositions: Record<string, string> = { SUPER_ADMIN: 'SUPER_ADMIN', ADMIN: 'ADMIN' };

export const egGovAr: Record<string, string> = {
    القاهرة: 'القاهرة',
    الاسكندرية: 'الاسكندرية',
    طنطا: 'طنطا',
    المنصورة: 'المنصورة',
    الإسماعيلية: 'الإسماعيلية',
    أسوان: 'أسوان',
    أسيوط: 'أسيوط',
    الأقصر: 'الأقصر',
    البحيرة: 'البحيرة',
    'بني سويف': 'بني سويف',
    'بور سعيد': 'بور سعيد',
    الغربية: 'الغربية',
    الشرقية: 'الشرقية',
    الدقهلية: 'الدقهلية',
    القليوبية: 'القليوبية',
    الجيزة: 'الجيزة',
    دمياط: 'دمياط',
    المنوفية: 'المنوفية',
    الفيوم: 'الفيوم',
    المنيا: 'المنيا',
    سيناء: 'سيناء',
    'قناة السويس': 'قناة السويس',
    'البحر الاحمر': 'البحر الاحمر ',
    'مرسى مطروح': 'مرسى مطروح',
    'الوادي الجديد': 'الوادي الجديد',
    'كفر الشيخ': 'كفر الشيخ',
    قنا: 'قنا',
    سوهاج: 'سوهاج',
};

export const levels: Record<string, string> = {
    GENERAL_PRACTITIONER: 'GENERAL_PRACTITIONER',
    SPECIALIST: 'SPECIALIST',
    ASSISTANT_LECTURER: 'ASSISTANT_LECTURER',
    CONSULTANT: 'CONSULTANT',
    LECTURER: 'LECTURER',
    ASSISTANT_PROFESSOR: 'ASSISTANT_PROFESSOR',
    PROFESSOR: 'PROFESSOR',
};

export const pharmaUsersTypes = [
    { MEDICAL_REP: 'MEDICAL REP' },
    { MANAGER: 'MANAGER' },
    { DISTRICT_MANAGER: 'DISTRICT MANAGER' },
    { ACCOUNT_MANAGER: 'ACCOUNT MANAGER' },
    { KEY_ACCOUNT_MANAGER: 'KEY ACCOUNT MANAGER' },
    { PRODUCT_MANAGER: 'PRODUCT MANAGER' },
    { PROJECT_MANAGER: 'PROJECT MANAGER' },
    { DIGITAL_MANAGER: 'DIGITAL MANAGER' },
    { CEO: 'CEO' },
    { CTO: 'CTO' },
];

// export const customApi = "https://dev.api.pharma.vcliniq.app/v1";
// export const api = 'https://dev.api.curawella.com/v2';
export const customApi = process.env.REACT_APP_PHARMA_API_LINK;
export const api = process.env.REACT_APP_API_LINK;
// export const api = 'https://subscription.dev.api.vcliniq.app/v2';

export const egGovEng = [
    'Cairo',
    'Alexandria',
    'Port Said',
    'Suez',
    'Damietta',
    'Dakahlia',
    'Ismeilia',
    'Sharkia',
    'Qalyubia',
    'Kafr ElSheikh',
    'Garbia',
    'Monufia',
    'Benisuif',
    'Aswan',
    'Luxor',
    'Asyut',
    'Behira',
    'Giza',
    'Faiyum',
    'Minya',
    'Sohag',
    'Qena',
    'Red Sea',
    'Matrouh',
    'New Valley',
    'North Sinai',
    'South Sinai',
];

export const egGovArabic = [
    'القاهرة',
    'الاسكندرية',
    'بورسعيد',
    'السويس',
    'دمياط',
    'الدقهلية',
    'الإسماعيلية',
    'الشرقية',
    'القليوبية',
    'كفر الشيخ',
    'الغربية',
    'المنوفية',
    'بني سويف',
    'أسوان',
    'الأقصر',
    'أسيوط',
    'البحيرة',
    'الجيزة',
    'الفيوم',
    'المنيا',
    'سوهاج',
    'قنا',
    'البحر الاحمر ',
    'مرسى مطروح',
    'الوادي الجديد',
    'شمال سيناء',
    'جنوب سيناء',
];
