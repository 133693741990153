import styles from './Notifications.module.scss';
import { TableComp, useFetch } from '@curawella/admin-package';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// appId: "e1ec8403-e146-4bd5-adc1-fcd095646eaa"
// name: "Test Doctor"
// packageName: "com.curawella.doctor"
// pushKey: "NjQwYTRhY2YtMTUyOC00YzcyLWI1NGItZDgxZDRmYmJiNjI3"
// type: "NOTIFICATION"
// version: "0"

const tableData = {
    title: 'Notifications',
    search: {
        show: true,
    },
    headers: [
        { title: 'Name', key: 'name' },
        { title: 'Package Name', key: 'packageName' },
        { title: 'Version', key: 'version' },
        { title: 'App ID', key: 'appId' },
        { title: 'Push Key', key: 'pushKey' },
        { title: 'Type', key: 'type' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const Notifications = (): JSX.Element => {
    const [data, loading, error, execute] = useFetch();
    const [renderData, setRenderData] = useState([]);
    const history = useHistory();

    // =================NAVIGATE TO ADD NEW NOTIFICATION APP=================
    const navigateToAdd = () => {
        history.push('notifications/add');
    };

    // =================GET NOTIFICATIONS DATA=================
    const getNotificationsData = useCallback(() => {
        execute('get', `admin/notificationApps`);
    }, [execute]);

    // =================GETTING NOTIFICATIONS DATA=================
    useEffect(() => {
        getNotificationsData();
    }, [getNotificationsData]);

    // =================NOTIFICATIONS DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('data: ', data);

        Object.entries(data).forEach((item: any) => {
            arr.push({
                ...item[1],
            });
        });
        setRenderData(arr);
    }, [data]);

    // =================NOTIFICATIONS DATA ERROR=================
    useEffect(() => {
        console.log('error: ', error);
    }, [error]);

    return (
        <div className={styles['notifications-container']}>
            <button onClick={navigateToAdd}>Add</button>
            {loading && <p>loading...</p>}
            {renderData.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderData}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                />
            )}
        </div>
    );
};

export default Notifications;
