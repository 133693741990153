import { Btn, DetailsView } from '@curawella/admin-package';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ctx } from '../../contexts/userContext';

import styles from './AdminProfile.module.scss';

const AdminProfile = () => {
    const { user } = useContext(ctx);
    const history = useHistory();
    const { ADMINS, CONTACTUS, DOCTORS, DRUGS, EARNINGS, NOTIFICATIONS, PATIENTS, SPECIALTIES, SUBSCRIPTION, firstName, lastName, position } = user;

    return (
        <div className={styles['view-admins-container']}>
            {/* header */}
            <h3>Admin profile</h3>

            {/* admins data */}
            {user && (
                <DetailsView
                    content={{
                        // uid: { value: uid },
                        name: { value: `${firstName}${' '}${lastName}` },
                        position: { value: position },
                        // isActive: { editable: false, title: 'is Active', type: 'TOGGLE', value: isActive === 1 },
                        ADMINS: { editable: false, title: 'Admin', type: 'TOGGLE', value: ADMINS === 1 },
                        CONTACTUS: { editable: false, title: 'Contact Us', type: 'TOGGLE', value: CONTACTUS === 1 },
                        DOCTORS: { editable: false, title: 'Doctors', type: 'TOGGLE', value: DOCTORS === 1 },
                        DRUGS: { editable: false, title: 'Drugs', type: 'TOGGLE', value: DRUGS === 1 },
                        EARNINGS: { editable: false, title: 'Earnings', type: 'TOGGLE', value: EARNINGS === 1 },
                        NOTIFICATIONS: { editable: false, title: 'Notifications', type: 'TOGGLE', value: NOTIFICATIONS === 1 },
                        PATIENTS: { editable: false, title: 'Patients', type: 'TOGGLE', value: PATIENTS === 1 },
                        SPECIALTIES: { editable: false, title: 'Specialties', type: 'TOGGLE', value: SPECIALTIES === 1 },
                        SUBSCRIPTION: { editable: false, title: 'Subscription', type: 'TOGGLE', value: SUBSCRIPTION === 1 },
                    }}
                />
            )}

            <Btn active handleClick={() => history.push('./changeAdminPass')}>
                Change Password
            </Btn>
        </div>
    );
};
export default AdminProfile;
