import { Btn, DetailsView, Loading, formInputTypesEnum, modalAlert } from '@curawella/admin-package';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SubscriptionsModel, { paymentMethodOptions } from '../../../models/Subscriptions';
import styles from './SubscriptionDetails.module.scss';

type statusType = { currentStatus: string; type: string };
type PropsType = { approvedAt: string; requestedAt: string; developmentAt: string; canceledAt: string; releasedAt: string };
type NameType = 'firstName' | 'lastName' | 'name' | 'nextPaymentDue' | 'price' | 'recurrent' | 'offerMonths';

const SubscriptionDetails = () => {
    const history = useHistory();
    const { doctorUid } = useParams<{ doctorUid: string }>();
    const [loading, execute, data] = SubscriptionsModel.getSubscriptionDetails();
    const [loadingEdit, executeEdit] = SubscriptionsModel.editSubscriptionDetails();
    const [loadingNewStatus, executeNewStatus] = SubscriptionsModel.ChangePlanStatus();
    const [status, setStatus] = useState<statusType>({ currentStatus: '', type: '' });
    const subscriptionDetails = data || {};
    const {
        planId,
        firstName,
        lastName,
        name,
        nextPaymentDue,
        paymentMethod,
        price,
        recurrent,
        offerMonths,
        releasedAt,
        requestedAt,
        approvedAt,
        developmentAt,
        canceledAt,
    } = subscriptionDetails;
    console.log('subscriptionDetails', subscriptionDetails);

    /**
     * Get Subscription Details
     */
    const getData = useCallback(() => {
        execute({ doctorUid }).catch((err) => console.log('error', err));
    }, [execute, doctorUid]);

    // Call getData function to get subscription details
    useEffect(() => {
        getData();
    }, [getData]);

    const onSubmit = (e: any, data: Record<NameType, string | boolean>, key: string) => {
        const body: any = { planId, type: key, doctorUid };

        for (const k in data) {
            const i = k as NameType;
            body[i] = data[i];
        }

        executeEdit(body)
            .then(() => getData())
            .catch((err) => {
                if (err === 'NO_DEFAULT_CARD') modalAlert({ type: 'ERROR', description: `Doctor ${firstName} ${lastName} has no default card` });
                else if (err === 'DEFAULT_CARD_ISSUE') modalAlert({ type: 'ERROR', description: `Cannot deduct the amount from doctor's default card` });
            });
    };

    // Call getStatus function to get subscription current status
    useEffect(() => {
        const { type, currentStatus } = getStatus({ requestedAt, approvedAt, developmentAt, releasedAt, canceledAt });

        setStatus({ type, currentStatus });
    }, [approvedAt, canceledAt, data, developmentAt, releasedAt, requestedAt, data]);

    const ChangePlanStatus = (action: string) => {
        const body = { doctorUid, action, planId };
        executeNewStatus(body)
            .then(() => getData())
            .catch((err) => {
                if (err === 'DEFAULT_CARD_ISSUE') modalAlert({ type: 'ERROR', description: `Failed to submit` });
            });
    };

    if (loading || loadingNewStatus || loadingEdit) {
        return <Loading />;
    }

    if (!planId) {
        return <h1>No current plan</h1>;
    }

    return (
        <div className={styles['edit-subscription-container']}>
            <DetailsView
                content={{
                    doctorName: { value: `${firstName}${' '}${lastName}` },
                    name: { value: name },
                    nextPaymentDue: {
                        value: nextPaymentDue ? moment(nextPaymentDue).format('MM/DD/YYYY') : '----',
                        title: 'Next Payment',
                        inputs: [{ name: 'nextPaymentDue', type: formInputTypesEnum.DATE }],
                    },
                    paymentMethod: {
                        value: paymentMethod,
                        inputs: [{ name: 'paymentMethod', type: formInputTypesEnum.SELECT, required: true, options: paymentMethodOptions }],
                    },
                    price: { value: `${Number(price).toFixed(2)} EGP/Month` },
                    recurrent: { value: recurrent },
                    offerMonths: { value: offerMonths || '----' },
                    status: { value: status.currentStatus },
                }}
                onSubmit={onSubmit}
            />
            <div className={styles['flex-parent']}>
                {status.type && (
                    <Btn className={styles.btn} active handleClick={() => ChangePlanStatus(status.type)}>
                        {status.type}
                    </Btn>
                )}
                <Btn className={styles['cancel-btn']} active handleClick={() => ChangePlanStatus('cancel')}>
                    Cancel
                </Btn>
                <Btn className={styles.btn} active handleClick={() => history.push(`/subscriptions/payment-history/${doctorUid}?planId=${planId}`)}>
                    payment history
                </Btn>
            </div>
        </div>
    );
};

export default SubscriptionDetails;

/**
 * Get Current Status of Subscription
 */
export function getStatus(props: PropsType) {
    const { requestedAt, approvedAt, developmentAt, releasedAt, canceledAt } = props;
    let type = '';
    let currentStatus = '';

    if (canceledAt !== null) {
        type = 'cancel';
        currentStatus = 'Canceled';
    } else if (releasedAt !== null) {
        type = '';
        currentStatus = 'Released';
    } else if (developmentAt !== null) {
        type = 'release';
        currentStatus = 'Developed';
    } else if (approvedAt !== null) {
        type = 'development';
        currentStatus = 'Approved';
    } else if (requestedAt !== null) {
        type = 'approve';
        currentStatus = 'Requested';
    } else type = '';

    return { type, currentStatus };
}
