import { Header, Loading, TableComp } from '@curawella/admin-package';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DrugModel, { getDrugsResponse } from '../../models/Drug';

const pageSize = 10;

const Drugs = () => {
    const history = useHistory();
    const [loading, execute, data] = DrugModel.getDrugs();
    const drugs: getDrugsResponse[] = data?.results || [];
    const length: number = data?.length || 0;

    const getData = useCallback(
        (pageNumber = undefined, search = undefined) => {
            execute({ pageSize, pageNumber, search });
        },
        [execute],
    );

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div>
            {/* header */}
            <Header title='Drugs' button={{ title: 'Create', onClick: () => history.push(`/addDrug`) }} />

            {/* loading */}
            {loading && <Loading />}

            {/* emails data */}
            <TableComp
                title={'Drugs'}
                headers={[{ key: 'drugId' }, { key: 'commercialName' }, { key: 'ndc' }]}
                data={drugs}
                pagination={{ pageLength: pageSize, showFirst: false, showLast: false }}
                showDataLength
                rowAction={(d) => history.push(`/drugs/${d.drugId}`)}
                endpoint={getData}
                dataLength={length}
            />
        </div>
    );
};

export default Drugs;
