import { useFetchType } from 'react-usefetch-models/build/types';
import { appId } from '../constants';
import backendConfig from './backendConfig';

export const paymentMethodOptions = [
    { title: 'Credit', value: 'CREDIT' },
    { title: 'Cash', value: 'CASH' },
];
export const recurrentOptions = [
    { title: 'Monthly', value: 'MONTHLY' },
    { title: 'Annual', value: 'ANNUALLY' },
];
export type getSubscriptionsListType = { pageSize: number; status?: string; pageNumber?: string; nextPaymentDue?: { from: string; to: string } };
type ChangePlanStatusType = { appId?: string; doctorUid: string; action: string; planId: number };
type getSubscriptionDetails = { doctorUid: string };
type editSubscriptionDetailsType = {
    planId: string;
    type: string;
    doctorUid: string;
    paymentMethod?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    nextPaymentDue?: string;
    price?: number;
    recurrent?: string;
    offerMonths?: string;
};
type subscribeDoctorType = { doctorUid: string; paymentMethod: string; name: string; price: number; recurrent: string; nextPaymentDue: string; appId?: string };
export type getDoctorPaymentHistoryType = { doctorUid: string; planId?: string };

class SubscriptionsModel {
    static getSubscriptionsList(): useFetchType<getSubscriptionsListType> {
        return backendConfig.config.patchData<getSubscriptionsListType>({
            endPoint: 'admin/subscriptions',
            bodyDefault: {
                nextPaymentDue: undefined,
            },
            queryDefault: { status: '', pageNumber: '1', pageSize: '10' },
        });
    }

    static ChangePlanStatus(): useFetchType<ChangePlanStatusType> {
        return backendConfig.config.updateData<ChangePlanStatusType>({
            endPoint: 'admin/subscriptions/:planId/:action',
            bodyDefault: { doctorUid: '', appId },
        });
    }

    static getSubscriptionDetails(): useFetchType<getSubscriptionDetails> {
        return backendConfig.config.getData<getSubscriptionDetails>({
            endPoint: 'admin/subscriptions/:doctorUid',
        });
    }

    static editSubscriptionDetails(): useFetchType<editSubscriptionDetailsType> {
        return backendConfig.config.updateData<editSubscriptionDetailsType>({
            endPoint: 'admin/subscriptions/edit/:planId/:type',
            bodyDefault: {
                doctorUid: '',
                firstName: undefined,
                lastName: undefined,
                name: undefined,
                nextPaymentDue: undefined,
                paymentMethod: undefined,
                price: undefined,
                recurrent: undefined,
                offerMonths: undefined,
            },
        });
    }
    static subscribeDoctor(): useFetchType<subscribeDoctorType> {
        return backendConfig.config.postData<subscribeDoctorType>({
            endPoint: '/admin/subscriptions/',
            bodyDefault: {
                doctorUid: '',
                paymentMethod: '',
                name: '',
                price: undefined,
                recurrent: '',
                nextPaymentDue: '',
                appId,
            },
        });
    }
    static getDoctorPaymentHistory(): useFetchType<getDoctorPaymentHistoryType> {
        return backendConfig.config.getData<getDoctorPaymentHistoryType>({
            endPoint: 'admin/subscriptions/payment/:doctorUid',
            queryDefault: { planId: '' },
        });
    }
}

export default SubscriptionsModel;
