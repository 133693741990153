import styles from './Select.module.scss';

type selectType = {
    selectedOption: string;
    options: string[];
    handleChange: (selected: string) => void;
};

const Select = ({selectedOption, options, handleChange}: selectType): JSX.Element => (
    <select
        className={styles['select-pending']}
        value={selectedOption}
        onChange={(event) => handleChange(event.target.value)}>
        {options.map((item, index) => (
            <option key={index} value={item}>
                {item}
            </option>
        ))}
    </select>
);

export default Select;
