import {FormInputs, InputTypes, useFetch} from '@curawella/admin-package';
import {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {customApi} from '../../../../../constants';
import styles from './AddSpecialty.module.scss';

const AddSpecialty = (): JSX.Element => {
    const [form, setForm] = useState({name: ''});

    const [formErrors, setFormErrors] = useState<{[key: string]: {[value: string]: null | boolean | string}}>({
        name: {
            value: null,
            msg: null
        }
    });
    const history = useHistory();

    const {companyId} = useParams<{companyId: string}>();

    const [data, loading, error, execute] = useFetch();
    const forms = [
        {
            name: 'Specialty Name',
            type: InputTypes.TEXT,
            value: form.name,
            name2: 'name',
            check: formErrors.name,
            key: 'name',
            label: 'Specialty Name'
        }
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const data = {...form, [e.currentTarget.name]: e.currentTarget.value};
        setForm(data);
    };

    // =================NAVIGATE TO VIEW ALL COMPANIES=================
    const navigateToViewCompanyDetails = useCallback(() => {
        history.push(`/companies/details/${companyId}`);
    }, [companyId, history]);

    // =================SUBMIT ADD COMPANY=================
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('form: ', form);
        setFormErrors({
            name: {
                value: null,
                msg: null
            }
        });

        if (!form.name) {
            setFormErrors({name: {value: false, msg: 'Required'}});
            return;
        }

        // /admin/companies/:specialtyId/clinic
        execute('post', `admin/companies/${companyId}/specialty`, form, customApi);
    };

    // =================DATA=================

    useEffect(() => {
        if (Object.keys(data).length) {
            setForm({name: ''});
            alert('added successfully');
            navigateToViewCompanyDetails();
        }
    }, [data, navigateToViewCompanyDetails]);

    // =================ERRORS=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    console.log('form: ', form);

    return (
        <div className={styles['companies-view-container']}>
            <h1>Add Specialty</h1>
            <button onClick={() => history.push('/companies/view')}>View All</button>

            {loading && <p>loading...</p>}

            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'submit'} />
            </Form>
        </div>
    );
};

export default AddSpecialty;
