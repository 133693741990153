import { TableComp, useFetch } from '@curawella/admin-package';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customApi } from '../../../constants';
import styles from './CompaniesUsers.module.scss';
const tableData = {
    title: 'Users',
    search: {
        show: true,
    },
    headers: [
        { title: 'UID', key: 'uid' },
        { title: 'Name', key: 'name' },
        { title: 'Position', key: 'position' },
        { title: 'Active', key: 'isActive' },
        { title: 'Actions', key: 'actions' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const CompaniesUsers = (): JSX.Element => {
    const [users, loading, error, executeUsers] = useFetch();

    const [renderCompanies, setRenderCompanies] = useState([]);

    const history = useHistory();
    const { companyId } = useParams<{ companyId: string }>();

    // =================COMPANIES DATA=================

    const getUsers = useCallback(() => {
        executeUsers('get', `admin/users/${companyId}`, undefined, customApi);
    }, [companyId, executeUsers]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    // =================COMPANIES DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('users: ', users);

        Object.entries(users).forEach((user: any) => {
            const { firstName, lastName, isActive } = user[1];
            const active = isActive ? 'Active' : 'Disabled';
            arr.push({
                ...user[1],
                isActive: active,
                name: `${firstName} ${lastName}`,
                actions: <Actions user={user[1]} getUsers={getUsers} />,
            });
        });
        setRenderCompanies(arr);
    }, [users, getUsers]);

    // =================COMPANIES DATA ERROR=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    return (
        <div className={styles['companies-users-container']}>
            <h1>Users</h1>

            <button onClick={() => history.push(`/companies/view`)}>Companies</button>
            {loading && <p>loading...</p>}

            {renderCompanies.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderCompanies}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                />
            )}
            <button onClick={() => history.push(`/companies/${companyId}/users/add`)}>Add</button>
        </div>
    );
};

export default CompaniesUsers;

const Actions = ({ user, getUsers }: Record<string, any>): JSX.Element => {
    const history = useHistory();
    const { companyId } = useParams<{ companyId: string }>();

    const navigateToUserDetails = () => {
        history.push(`/companies/${companyId}/users/details/${user.uid}`);
    };
    return (
        <div className={styles['actions-btns-container']}>
            <button className={styles['action-btn']} onClick={navigateToUserDetails}>
                Details
            </button>
        </div>
    );
};
