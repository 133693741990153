import { TableComp, useFetch } from '@curawella/admin-package';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Earnings.module.scss';
import moment from 'moment';

// amount: 377400
// notPaid: 140850
// online: 189050
// onsite: 8500
// originalPrice: 305000
// total: 381600

const tableData = {
    title: 'Earnings',
    search: {
        show: true,
    },
    headers: [
        { title: 'Dr. Name', key: 'name' },
        { title: 'Amount', key: 'amount' },
        { title: 'Not Paid', key: 'notPaid' },
        { title: 'Online', key: 'online' },
        { title: 'Paid Onsite', key: 'onsite' },
        { title: 'Total', key: 'total' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const Earnings = (): JSX.Element => {
    const [earnings, loading, error, executeEarnings] = useFetch();

    const [renderEarnings, setRenderEarnings] = useState([]);
    const [selectedDates, setSelectedDates] = useState({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
    });
    const history = useHistory();

    const logRow = (row: any) => {
        console.log('row: ', row);
        history.push('/doctor/' + row.doctorUid);
    };

    // =================GETTING DOCTORS DATA=================
    useEffect(() => {
        console.log('selectedDates', selectedDates);

        executeEarnings('get', `admin/earnings/${moment(selectedDates.from).format()}/${moment(selectedDates.to).format()}`);
    }, [executeEarnings, selectedDates]);

    // =================FORMAT NUMBER=================

    const formatNumber = (value: string) => {
        return (parseFloat(value) / 100).toLocaleString('en-US');
    };

    // =================DOCTORS DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('earnings', earnings);

        if (Array.isArray(earnings)) {
            earnings.forEach((earn: any) => {
                console.log(earn);

                arr.push({
                    doctorUid: earn.doctorUid,
                    name: `${earn.firstName} ${earn.lastName}`,
                    amount: formatNumber(earn.amount),
                    notPaid: formatNumber(earn.notPaid),
                    online: formatNumber(earn.online),
                    onsite: formatNumber(earn.onsite),
                    total: formatNumber(earn.notPaid + earn.online + earn.onsite),
                });
            });
        }
        setRenderEarnings(arr);
    }, [earnings]);

    // =================DOCTORS DATA ERROR=================
    useEffect(() => {
        if (Object.keys(error).length) {
            console.log('error: ', error);
        }
    }, [error]);

    return (
        <div className={styles['earnings-container']}>
            {/* <h1>doctors</h1> */}
            {loading && <p>loading...</p>}

            <div className={styles['inputs-container']}>
                <div className={styles['input-container']}>
                    <label>From</label>
                    <input type='date' value={selectedDates.from} onChange={(e) => setSelectedDates({ ...selectedDates, from: e.target.value })} />
                </div>
                <div className={styles['input-container']}>
                    <label>To</label>
                    <input type='date' value={selectedDates.to} onChange={(e) => setSelectedDates({ ...selectedDates, to: e.target.value })} />
                </div>
            </div>
            {renderEarnings.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderEarnings}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    rowAction={logRow}
                />
            )}
        </div>
    );
};

export default Earnings;
