import { Btn, DataView, DataViewItemTypes, modalAlert } from '@curawella/admin-package';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    extractRequiredKeys,
    formatValue,
    getArticleContentList,
    getDate,
    getGender,
    getRecipientsList,
    getSpecialtiesList,
    getTitle,
} from '../../helpers/format';
import styles from './ViewApprovement.module.scss';
import { ctx } from '../../contexts/userContext';
import ApprovementModal from '../../models/approvement';

const ViewApprovement: React.FC = () => {
    const [formData, setFormData] = useState([]);
    const [loading, executeGetApprovementDetails] = ApprovementModal.getApprovementDetails();
    const [createApproveLoading, executeCreateApprove] = ApprovementModal.createApprovement();
    const { user } = useContext(ctx);
    const { companyId, elementType, elementId } = useParams<{ companyId: string; elementType: string; elementId: string }>();
    const history = useHistory();

    const formatValue = (key: string, value: string | number): any => {
        switch (key) {
            case 'image':
            case 'overviewImage':
                return [{ link: value }];

            case 'createdAt':
            case 'timestamp':
                return getDate(value as string);

            case 'isActive':
                return value === '1' ? 'Active' : 'Disabled';

            case 'gender':
                return getGender(value as string);

            default:
                return value;
        }
    };

    const handleData = useCallback((data: Record<string, string | number | Record<string, string | number>[]> | any): void => {
        const arr: any = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const value = data[key];

                const mainData: any = {
                    name: key,
                    title: getTitle(key),
                    type: 'text',
                    name2: key,
                    disabled: true,
                    editable: false,
                };
                if (key === 'receivers') {
                    mainData.options = getRecipientsList(value as { doctorName: string }[]);
                    mainData.type = DataViewItemTypes.LIST;
                } else if (key === 'specialties') {
                    mainData.options = getSpecialtiesList(value as { specialtyName: string }[]);
                    mainData.type = DataViewItemTypes.LIST;
                } else if (key === 'elements') {
                    mainData.options = getArticleContentList(value);
                    mainData.type = DataViewItemTypes.LIST;
                } else if (key === 'image' || key === 'overviewImage') {
                    mainData.value = formatValue(key, value as string);
                    mainData.type = DataViewItemTypes.IMAGES;
                } else {
                    mainData.value = formatValue(key, value as string);
                }

                arr.push({ ...mainData });
            }
        }
        setFormData(arr);
    }, []);

    const getApprovementDetails = useCallback((): void => {
        executeGetApprovementDetails({
            companyId,
            elementId,
            elementType,
        })
            .then((res) => {
                console.log('get approvement details res results: ', res);
                const obj = Object.fromEntries(extractRequiredKeys(elementType).map((k) => [k, res[k]]));

                handleData(obj);
            })
            .catch((err) => {
                console.log('get approvement details err: ', err);
            });
    }, [companyId, elementId, elementType, executeGetApprovementDetails, handleData]);

    useEffect(() => {
        getApprovementDetails();
    }, [getApprovementDetails]);

    const navigateToAllRequests = (): void => {
        history.push('/approvement');
    };

    const approveRequest = (): void => {
        const { uid: approvedBy } = user;
        console.log('approvedBy: ', approvedBy);
        console.log('elementId: ', elementId);
        console.log('elementType: ', elementType);

        executeCreateApprove({
            approvedBy,
            elementId,
            elementType,
            isApproved: true,
        }).then((res) => {
            console.log('approve request res: ', res);

            modalAlert({
                type: 'SUCCESS',
                description: `Request has been approved successfully`,
                autoClose: true,
                confirmButton: { action: navigateToAllRequests },
            });
        });
    };

    return (
        <div className={styles['view-admin-container']}>
            <h3>View approvement details</h3>
            <Btn active handleClick={navigateToAllRequests} disabled={false}>
                All requests
            </Btn>
            {loading && 'loading...'}
            <DataView content={formData} />
            <Btn active handleClick={approveRequest} disabled={createApproveLoading} className={styles['reset-btn']}>
                Approve
            </Btn>
        </div>
    );
};

export default ViewApprovement;
