import {BackendCall} from 'react-usefetch-models';
import {customApi} from '../constants';

//  API Backend base url
const baseApiUrl = customApi;

class PharmaBackend {
    config!: BackendCall;

    constructor() {
        this.initializeBackend(undefined);
    }

    updateAuthToken(authToken: string): void {
        this.initializeBackend(authToken);
    }

    private initializeBackend(authToken?: string): void {
        // Default Headers containing headers to be set by default to any request unless it overwritten
        const defaultHeaders: Record<string, string> = {'Content-Type': 'application/json'};

        if (authToken) {
            defaultHeaders.auth_token = authToken;
        }

        if (!baseApiUrl) {
            throw new Error('Pharmaa Backend API NOT FOUND');
        }

        /**
         * Function to be executed after the success and do whatever you want
         * @param {any} res
         */
        async function defaultSuccessFunction(res: any): Promise<any> {
            if (!res.success) {
                const {code} = res;
                console.log('code: ', code);
                throw new Error(code);
            }
            return res;
        }
        /**
         * Function to be executed after the catching the error and do whatever you want
         * @param {any} err the error as a first and only parameter
         * @return {any} Whatever u want to be in the rejected state
         */
        async function defaultErrorFunction(err: any): Promise<any> {
            return err;
        }
        this.config = new BackendCall(baseApiUrl, defaultHeaders, defaultSuccessFunction, defaultErrorFunction);
    }
}

export default new PharmaBackend();
