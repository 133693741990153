// import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';

type P = {
    title: string;
    headers: ({ key: string; title: string } | string)[];
    data: ({ date: string } & Record<string, number>)[];
};
const RenderChart = ({ data, headers, title }: P) => {
    const dates: string[] = data.map((v) => v.date);
    const series = [];

    for (let i = 0; i < headers.length; i++) {
        const attribute = headers[i];
        const { key, title } = typeof attribute === 'string' ? { key: attribute, title: attribute } : { key: attribute.key, title: attribute.title };

        const arr: number[] = data.map((v) => v[key] || 0);
        series.push({ name: title, data: arr });
    }

    return <ReactApexChart series={series} options={options(title, dates)} type='line' height={500} width={'100%'} />;
};

export default RenderChart;

const options = (title: string, dates: string[]): ApexCharts.ApexOptions => ({
    chart: { height: 500, type: 'line', stacked: false, toolbar: { show: false } },
    noData: { text: 'There is no data' },
    stroke: { width: [5, 5, 5, 5, 5, 5], curve: 'smooth' },
    plotOptions: { bar: { columnWidth: '50%' } },
    title: { text: title },
    colors: ['#ef271b', '#66DA26', '#2E93fA', '#ee4469', '#FF9800', '#29335c'],
    labels: dates && dates,
    markers: { size: 1 },
    xaxis: { type: 'datetime' },
    yaxis: { min: 0 },
    tooltip: {
        marker: { show: true },
        // fixed: { enabled: true, position: 'topLeft', offsetY: 30, offsetX: 60 },
        x: { show: true, format: 'ddd dd MMM' },
    },
});

export function fillMissingDates(data: ({ date: string } & Record<string, number>)[]) {
    const dates = data.map((v) => v.date);
    const datesUnix = dates.map((v) => moment(v).unix());
    const startDateIndex = datesUnix.findIndex((v) => Math.min(...datesUnix) === v);
    const endDateIndex = datesUnix.findIndex((v) => Math.max(...datesUnix) === v);
    const { startDate, endDate } = { startDate: moment(dates[startDateIndex]), endDate: moment(dates[endDateIndex]) };
    const finalData: ({ date: string } & Record<string, any>)[] = [];

    const cDate = moment(startDate);
    while (cDate.isSameOrBefore(endDate)) {
        const dataIndex = data.findIndex((v) => moment(v.date).isSame(cDate));

        if (dataIndex !== -1) {
            finalData.push({ ...data[dataIndex], date: cDate.format('YYYY-MM-DD') });
        } else {
            finalData.push({ date: cDate.format('YYYY-MM-DD') });
        }

        cDate.add(1, 'day');
    }

    return finalData;
}

export function fillMissingWeeks(data: ({ weekStart: string } & Record<string, number>)[]) {
    const dates = data.map((v) => v.weekStart);
    const datesUnix = dates.map((v) => moment(v).unix());
    const startDateIndex = datesUnix.findIndex((v) => Math.min(...datesUnix) === v);
    const endDateIndex = datesUnix.findIndex((v) => Math.max(...datesUnix) === v);
    const { startDate, endDate } = { startDate: moment(dates[startDateIndex]), endDate: moment(dates[endDateIndex]) };
    const finalData: ({ date: string } & Record<string, any>)[] = [];

    const cDate = moment(startDate);
    while (cDate.isSameOrBefore(endDate)) {
        const dataIndex = data.findIndex((v) => moment(v.weekStart).isSame(cDate));

        if (dataIndex !== -1) {
            finalData.push({ ...data[dataIndex], date: cDate.format('YYYY-MM-DD') });
        } else {
            finalData.push({ date: cDate.format('YYYY-MM-DD') });
        }

        cDate.add(1, 'week');
    }

    return finalData;
}

export function incrementChartData(data: ({ date: string } & Record<string, number>)[]) {
    data = data.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

    for (let i = 1; i < data.length; i++) {
        const current = data[i].count || 0;
        const prev = data[i - 1].count || 0;

        data[i].count = current + prev;
    }

    console.log(data);

    return data;
}
