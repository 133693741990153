import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { TableComp } from '@curawella/admin-package';

import styles from './Doctor.module.scss';

import DoctorModel, { getDoctorEarningsType } from '../../models/Doctor';

import Loading from '../../components/Loading';

export type earningsType = { doctorUid: string };
export type earnings = {
    amount: number;
    appointmentTimestamp: string;
    doctorEarn: number;
    fees: number;
    firstName: string;
    lastName: string;
    orderId: string;
    originalPrice: number;
    paymentMethod: string;
    paymentTime: string;
    status: number;
    total: number;
    type: string;
};

const tableData = {
    title: 'Earnings',
    search: { show: true },
    showDataLength: true,
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    headers: [
        { title: 'Name', key: 'name' },
        { title: 'Type', key: 'type' },
        { title: 'Time', key: 'appointmentTimestamp' },
        { title: 'Amount', key: 'amount' },
        { title: 'Doctor Earn', key: 'doctorEarn' },
        { title: 'total', key: 'total' },
        { title: 'Method', key: 'paymentMethod' },
        { title: 'Order ID', key: 'orderId' },
        { title: 'payment Time', key: 'paymentTime' },
        { title: 'status', key: 'status' },
    ],
};

const Earnings = ({ doctorUid }: earningsType): JSX.Element => {
    const [doctorEarningsLoading, executeDoctorEarnings, doctorEarnings] = DoctorModel.getDoctorEarnings();

    const [renderEarnings, setRenderEarnings] = useState<any[]>([]);
    const [selectedDates, setSelectedDates] = useState<{ from: string; to: string }>({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
    });

    /**
     * Get doctor earnings
     */
    const getDrEarning = useCallback(() => {
        const data: getDoctorEarningsType = {
            doctorUid,
            from: moment(selectedDates.from).format(),
            to: moment(selectedDates.to).format(),
        };

        executeDoctorEarnings(data);
    }, [doctorUid, executeDoctorEarnings, selectedDates.from, selectedDates.to]);

    /**
     * Handel doctor earnings for rendering
     */
    const formatDrEarnings = (data: []) => {
        const arr: any[] = [];

        data.forEach((item: earnings) => {
            arr.push({
                name: `${item.firstName} ${item.lastName}`,
                type: item.type,
                appointmentTimestamp: moment(item.appointmentTimestamp).format('DD/MM, LT'),
                originalPrice: item.originalPrice / 100,
                amount: item.amount / 100,
                fees: item.fees / 100,
                doctorEarn: item.doctorEarn / 100,
                total: item.total / 100,
                paymentMethod: item.paymentMethod,
                orderId: item.orderId,
                paymentTime: moment(item.paymentTime).format('DD/MM, LT'),
                status: item.status === 0 ? 'unpaid' : 'paid',
            });
        });
        setRenderEarnings(arr);
    };

    // Call getDrEarning function to get doctor earnings
    useEffect(() => {
        getDrEarning();
    }, [getDrEarning]);

    // Call formatDrEarnings function to handle doctor earnings
    useEffect(() => {
        if (doctorEarnings) {
            console.log('doctorEarnings: ', doctorEarnings);
            if (doctorEarnings) {
                formatDrEarnings(doctorEarnings.appointments);
            }
        }
    }, [doctorEarnings]);

    if (doctorEarningsLoading) {
        return <Loading />;
    }

    return (
        <div>
            {/* title */}
            <p className={styles['dr-name']}>Earnings</p>

            {/* TODO: Try "react-date-range" */}
            {/* date */}
            <div className={styles['inputs-container']}>
                {/* start date */}
                <div className={styles['input-container']}>
                    <label>From</label>
                    <input type='date' value={selectedDates.from} onChange={(e) => setSelectedDates({ ...selectedDates, from: e.target.value })} />
                </div>

                {/* end date */}
                <div className={styles['input-container']}>
                    <label>To</label>
                    <input type='date' value={selectedDates.to} onChange={(e) => setSelectedDates({ ...selectedDates, to: e.target.value })} />
                </div>
            </div>

            {/* earnings data */}
            {renderEarnings.length > 0 ? (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderEarnings}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                />
            ) : (
                // no earnings message
                <p className={styles['no-data']}>there is no data in this period</p>
            )}
        </div>
    );
};

export default Earnings;
