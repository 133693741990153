import {Route} from 'react-router';
import CompaniesCreate from './companiesCreate/CompaniesCreate';
import AddClinic from './companiesDetails/companiesClinics/AddClinic/AddClinic';
import CompaniesDetails from './companiesDetails/CompaniesDetails';
import AddSpecialty from './companiesDetails/companiesSpecialities/AddSpecialty/AddSpecialty';
import CompaniesUsers from './companiesUsers/CompaniesUsers';
import CompaniesUsersCreate from './companiesUsers/companiesUsersCreate/CompaniesUsersCreate';
import CompaniesUsersDetails from './companiesUsers/companiesUsersDetails/CompaniesUsersDetails';
import CompaniesView from './companiesView/CompaniesView';

const Companies = (): JSX.Element => {
    return (
        <>
            <Route exact path='/companies/view' component={CompaniesView} />
            <Route exact path='/companies/add' component={CompaniesCreate} />
            <Route exact path='/companies/details/:companyId' component={CompaniesDetails} />
            <Route exact path='/companies/details/:companyId/clinics/add' component={AddClinic} />
            <Route exact path='/companies/details/:companyId/specialties/add' component={AddSpecialty} />

            <Route exact path='/companies/:companyId/users' component={CompaniesUsers} />
            <Route exact path='/companies/:companyId/users/add' component={CompaniesUsersCreate} />
            <Route exact path='/companies/:companyId/users/details/:userUid' component={CompaniesUsersDetails} />
        </>
    );
};

export default Companies;
