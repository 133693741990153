import styles from './AddNotificationApp.module.scss';
import {useHistory} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {FormInputs, useFetch} from '@curawella/admin-package';
import {Form} from 'react-bootstrap';

const AddNotificationApp = (): JSX.Element => {
    const [form, setForm] = useState({
        appId: '',
        pushKey: '',
        name: '',
        type: '',
        packageName: ''
    });
    const history = useHistory();
    const [data, loading, error, execute] = useFetch();

    const forms = [
        {
            name: 'App ID',
            type: 'text',
            value: form.appId,
            name2: 'appId'
        },
        {
            name: 'Push Key',
            type: 'text',
            value: form.pushKey,
            name2: 'pushKey'
        },
        {
            name: 'Name',
            type: 'text',
            value: form.name,
            name2: 'name'
        },
        {
            name: 'Type',
            type: 'select',
            options: [{VOIP: 'voip'}, {NOTIFICATION: 'Notification'}],
            value: form.type,
            name2: 'type'
        },
        {
            name: 'Package Name',
            type: 'text',
            value: form.packageName,
            name2: 'packageName'
        }
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const data = {...form, [e.currentTarget.name]: e.currentTarget.value};
        setForm(data);
    };

    // =================NAVIGATE TO ADD NEW NOTIFICATION APP=================
    const navigateToViewAll = useCallback(() => {
        history.push('/notifications');
    }, [history]);

    // =================SUBMIT ADD NOTIFICATION=================
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('form: ', form);

        execute('post', 'admin/notificationApps', form);
    };

    // =================ADD NOTIFICATION DATA RECEIVED=================
    useEffect(() => {
        console.log('data: ', data);
        if (Object.keys(data).length) {
            alert('added successfully');
            navigateToViewAll();
        }
    }, [data, navigateToViewAll]);

    // =================ADD NOTIFICATION DATA ERROR=================
    useEffect(() => {
        console.log('error: ', error);
    }, [error]);

    return (
        <div className={styles['add-notification-container']}>
            <button onClick={navigateToViewAll}>View All</button>
            <p>Add New Notification App</p>
            <Form onSubmit={handleSubmit}>
                <FormInputs inputs={forms} handleChange={handleChange} />
                <input type='submit' value={loading ? 'loading...' : 'submit'} />
            </Form>
        </div>
    );
};

export default AddNotificationApp;
