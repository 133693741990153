import { Btn, TableComp } from '@curawella/admin-package';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAdminType, getDate, getRequestType } from '../../helpers/format';
import ApprovementModal from '../../models/approvement';
import styles from './Approvement.module.scss';

const tableData = {
    title: 'Approvement',
    search: {
        show: true,
    },
    headers: [
        {
            title: 'Company',
            key: 'company',
            format: (value: string) => getAdminType(value),
        },
        {
            title: 'Name',
            key: 'name',
        },
        {
            title: 'Type',
            key: 'type',
            format: (value: string) => getRequestType(value),
        },
        {
            title: 'Date',
            key: 'timestamp',
            format: (value: string) => getDate(value),
        },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const Approvement: React.FC = () => {
    const [renderData, setRenderData] = useState([]);
    const [loading, executeGetApprovement] = ApprovementModal.getApprovement();

    const history = useHistory();

    const handleData = useCallback((data: Record<string, string | number>[]): void => {
        const arr: any = [];

        data.forEach((element) => {
            arr.push({
                name: `${element.firstName} ${element.lastName}`,
                company: element.companyName,
                ...element,
            });
        });

        setRenderData(arr);
    }, []);

    const getApprovement = useCallback((): void => {
        executeGetApprovement({})
            .then((res) => {
                console.log('get Approvement res results: ', res.results);
                handleData(res);
            })
            .catch((err) => {
                console.log('get Approvement err: ', err);
            });
    }, [executeGetApprovement, handleData]);

    useEffect(() => {
        getApprovement();
    }, [getApprovement]);

    const navigateToAdminDetails = (row: Record<string, string>): void => {
        console.log('row: ', row);

        const { companyId, type: elementType, id: elementId } = row;
        history.push(`/Approvement/${companyId}/${elementType}/${elementId}`);
    };

    return (
        <div className={styles['approvement-container']}>
            <h3>View Approvement</h3>
            {loading && 'loading...'}
            {renderData.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderData}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    rowAction={navigateToAdminDetails}
                />
            )}
        </div>
    );
};

export default Approvement;
