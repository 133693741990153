import Backend from './backendConfig';

export type getOrdersType = { search?: string; pageNumber?: string; pageSize?: number };

class OrderModel {
    /**
     * Get Orders list
     */
    static get() {
        return Backend.config.getData<getOrdersType>({
            endPoint: 'admin/orders',
            queryDefault: { approved: '', search: '', pageNumber: '', pageSize: '' },
        });
    }
}

export default OrderModel;
