import { Loading, TableComp } from '@curawella/admin-package';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SubscriptionsModel, { getDoctorPaymentHistoryType } from '../../../models/Subscriptions';

const PaymentHistory = () => {
    const { doctorUid } = useParams<{ doctorUid: string }>();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const planId = params.get('planId');
    const [loading, execute, data] = SubscriptionsModel.getDoctorPaymentHistory();
    const paymentHis = data || [];

    /**
     * Get Doctor Payment History
     */
    useEffect(() => {
        const d: getDoctorPaymentHistoryType = { doctorUid };

        if (planId) {
            d.planId = planId;
        }

        execute(d);
    }, [doctorUid, execute, planId]);

    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            <TableComp
                title={'Payment History'}
                search={false}
                headers={[
                    { key: 'name' },
                    { key: 'dueDate', format: (v: string) => moment(v).format('DD/MM/YYYY h:mmA') },
                    { key: 'paymentTime', title: 'Payment Time', format: (v: string) => moment(v).format('DD/MM/YYYY h:mmA') },
                    { key: 'amount', format: (v: string) => `${(Number(v) / 100).toFixed(2)} EGP` },
                    { key: 'total', format: (v: string) => `${(Number(v) / 100).toFixed(2)} EGP` },
                ]}
                data={paymentHis}
            />
        </div>
    );
};
export default PaymentHistory;
