import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableComp } from '@curawella/admin-package';
import { numericBoolean } from '../../types/GeneralTypes';
import DoctorModel, { getDoctorsType } from '../../models/Doctor';
import Select from '../../components/Select/Select';
import moment from 'moment';
import Loading from '../../components/Loading';
import RenderChart, { fillMissingDates, fillMissingWeeks, incrementChartData } from '../../components/Chart';

const selectOptions = ['Not Approved', 'Approved'];

const pageLength = 10;
const headers = [
    { title: 'Dr. Name', key: 'name' },
    { key: 'createdAt', format: (d: any) => moment(d).format('llll') },
    // { title: 'uid', key: 'uid' },
    { title: 'Specialty', key: 'specialtyNameEn' },
    { title: '# Patients', key: 'patients' },
];

const Doctors = (): JSX.Element => {
    const [loading, execute, data] = DoctorModel.getDoctors();
    const [loadingGraphs, executeGraphs, graphsData] = DoctorModel.getDoctorsGraph();
    const [selected, setSelected] = useState<string>(selectOptions[0]);

    const history = useHistory();
    const doctors = data?.doctors.map((p: any) => ({ ...p, name: `${p.firstName} ${p.lastName}` })) || [];

    /**
     * Get doctors
     */
    const getDoctors = useCallback(
        (pageNumber = undefined, query = undefined) => {
            const approved: numericBoolean = selected === selectOptions[0] ? 0 : 1;
            const d: getDoctorsType = { approved, pageSize: pageLength };

            if (query) {
                d.search = query;
            }
            if (pageNumber) {
                d.pageNumber = pageNumber;
            }

            execute(d)
                .then((res) => {
                    console.log('res', res);
                })
                .catch((err) => {
                    console.log('err', err);
                });
        },
        [execute, selected],
    );

    const getDoctorsGraph = useCallback(() => {
        executeGraphs(undefined);
        // .then((res) => {
        //     console.log('res', res);
        // })
        // .catch((err) => {
        //     console.log('err', err);
        // });
    }, [executeGraphs]);

    // Call getPatientData function to get patient data
    useEffect(() => {
        getDoctors();
        getDoctorsGraph();
    }, [getDoctors, getDoctorsGraph]);

    return (
        <>
            {loading && <Loading />}
            <Select selectedOption={selected} options={selectOptions} handleChange={(selected) => setSelected(selected)} />
            <TableComp
                title={'Doctors'}
                headers={headers}
                data={doctors}
                pagination={{ pageLength }}
                showDataLength
                dataLength={data?.length}
                rowAction={(patient) => history.push(`/doctor/${patient.uid}`)}
                endpoint={getDoctors}
            />

            <RenderChart title='Doctors' headers={['count']} data={graphsData ? fillMissingDates(graphsData.growth) : []} />
            <RenderChart title='Doctors' headers={['count']} data={graphsData ? incrementChartData(fillMissingWeeks(graphsData.growthInc)) : []} />
        </>
    );
};

export default Doctors;
