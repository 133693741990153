// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uCppxmT1Eo8ViMmtu3D5 .dy0z3pxWgit_IpLgMptX{display:flex;justify-content:space-around;padding:1rem}.hNTuCYO7vfTP5cD5qron{display:flex;flex-direction:column}.hNTuCYO7vfTP5cD5qron .RhoYRDxYEodPt3ZTDBfL{margin:1rem}.hNTuCYO7vfTP5cD5qron .jVU9Z5NnebPSsoos3Tpe{font-size:1.25rem}._aupE8oSv4G74oAZ9Y7e .RP5tkBXp4ldIeZmswz9P{font-size:1.25rem;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/pages/patient/Patient.module.scss"],"names":[],"mappings":"AAGI,4CACI,YAAA,CACA,4BAAA,CACA,YAAA,CAIR,sBACI,YAAA,CACA,qBAAA,CAEA,4CACI,WAAA,CAGJ,4CACI,iBAAA,CAKJ,4CACI,iBAAA,CACA,gBAAA","sourcesContent":["@import '../../assets/styles/colors.scss';\n\n.container {\n    .button-list-container {\n        display: flex;\n        justify-content: space-around;\n        padding: 1rem;\n    }\n}\n\n.appointment-container {\n    display: flex;\n    flex-direction: column;\n\n    .table-container {\n        margin: 1rem;\n    }\n\n    .no-data {\n        font-size: 1.25rem;\n    }\n}\n\n.cashbox-container {\n    .header {\n        font-size: 1.25rem;\n        font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uCppxmT1Eo8ViMmtu3D5",
	"button-list-container": "dy0z3pxWgit_IpLgMptX",
	"appointment-container": "hNTuCYO7vfTP5cD5qron",
	"table-container": "RhoYRDxYEodPt3ZTDBfL",
	"no-data": "jVU9Z5NnebPSsoos3Tpe",
	"cashbox-container": "_aupE8oSv4G74oAZ9Y7e",
	"header": "RP5tkBXp4ldIeZmswz9P"
};
export default ___CSS_LOADER_EXPORT___;
