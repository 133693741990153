import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Btn, TableComp } from '@curawella/admin-package';

import styles from './Admins.module.scss';
import AdminsModal from '../../models/admins';

import { getActiveState, getAdminType } from '../../helpers/format';
import Loading from '../../components/Loading';

const tableData = {
    title: 'Admins',
    showDataLength: true,
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    headers: [
        { title: 'Name', key: 'name' },
        { title: 'Position', key: 'position', format: (value: string) => getAdminType(value) },
        { title: 'Active', key: 'isActive', format: (value: string) => getActiveState(value) },
    ],
};

const Admins: React.FC = () => {
    const [loading, executeGetAdmins] = AdminsModal.getAdmins();

    const [renderData, setRenderData] = useState([]);
    const history = useHistory();

    /**
     * Handel admins data for rendering
     */
    const handleData = useCallback((data: Record<string, string | number>[]): void => {
        const arr: any = [];

        data.forEach((element) => {
            arr.push({
                name: `${element.firstName} ${element.lastName}`,
                ...element,
            });
        });

        setRenderData(arr);
    }, []);

    /**
     * Get admins
     */
    const getAdmins = useCallback((): void => {
        executeGetAdmins({})
            .then((res) => handleData(res))
            .catch((err) => console.log('get admins err: ', err));
    }, [executeGetAdmins, handleData]);

    // Call getAdmins function to get admins
    useEffect(() => getAdmins(), [getAdmins]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles['view-admins-container']}>
            {/* header */}
            <h3>View Admins</h3>

            {/* create new admin button */}
            <Btn active handleClick={() => history.push(`/admins/create`)}>
                Create
            </Btn>

            {/* admins data */}
            {renderData.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderData}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    rowAction={(admin) => history.push(`/admins/${admin.uid}`)}
                />
            )}
        </div>
    );
};

export default Admins;
