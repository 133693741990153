import { InputTypes, ModalComp, ModalTypes, TableComp, useFetch } from '@curawella/admin-package';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customApi } from '../../../../constants';
import styles from './CompaniesClinics.module.scss';
const tableData = {
    title: 'Clinics',
    search: {
        show: true,
    },
    headers: [
        { title: 'Clinic Id', key: 'clinicId' },
        { title: 'Doctor Name', key: 'doctorName' },
        { title: 'Doctor Uid', key: 'doctorUid' },
        { title: 'Specialty Id', key: 'specialtyId' },
        { title: 'Specialty Name', key: 'specialtyName' },
        { title: 'Active', key: 'isActive' },
        { title: 'Actions', key: 'actions' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};

const CompaniesClinics = ({ clinics, getCompany, specialties }: any): JSX.Element => {
    // const [companies, loading, error, executeCompanies] = useFetch();
    const { companyId } = useParams<{ companyId: string }>();
    const [renderClinics, setRenderClinics] = useState([]);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [form, setForm] = useState({
        doctorName: '',
        specialtyId: '',
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: { [value: string]: null | boolean | string } }>({
        doctorName: {
            value: null,
            msg: null,
        },
        specialtyId: {
            value: null,
            msg: null,
        },
    });
    const history = useHistory();

    const forms = [
        {
            name: 'Doctor Name',
            type: InputTypes.TEXT,
            value: form.doctorName,
            name2: 'name',
            check: formErrors.doctorName,
            key: 'doctorName',
            label: 'Doctor Name',
        },
        {
            name: 'Specialty',
            type: InputTypes.SELECT,
            options: specialties,
            value: form.specialtyId,
            name2: 'specialtyId',
            check: formErrors.specialtyId,
            key: 'specialtyId',
            label: 'Specialty',
        },
    ];
    const navigateToAddClinic = () => {
        // setShowAddModal((prev) => !prev);
        history.push(`/companies/details/${companyId}/clinics/add`);
    };

    // =================COMPANIES DATA=================

    // const getCompanies = useCallback(() => {
    //     executeCompanies('get', 'admin/companies/', undefined, customApi);
    // }, [executeCompanies]);

    // useEffect(() => {
    //     getCompanies();
    // }, [executeCompanies, getCompanies]);

    // =================COMPANIES DATA RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('clinics: ', clinics);

        clinics.forEach((clinic: any) => {
            const active = clinic.isActive ? 'Active' : 'Disabled';
            arr.push({
                ...clinic,
                isActive: active,
                actions: <Actions clinic={clinic} getCompany={getCompany} />,
            });
        });
        setRenderClinics(arr);
    }, [clinics, getCompany]);

    const onSuccess = useCallback(
        (data: any) => {
            console.log('received data: ', data);

            setShowAddModal(false);
            getCompany();
        },
        [getCompany],
    );

    // =================COMPANIES DATA ERROR=================
    // useEffect(() => {
    //     if (Object.keys(error).length) {
    //         console.log('error: ', error);
    //     }
    // }, [error]);

    return (
        <div className={styles['specialties-clinics']}>
            {/* <h1>Companies</h1> */}

            {/* {loading && <p>loading...</p>} */}

            {renderClinics.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderClinics}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    // rowAction={logRow}
                />
            )}
            <button onClick={navigateToAddClinic}>Add Clinic</button>
            {/* /admin/companies/:specialtyId/clinic */}

            <ModalComp
                title='Add clinic'
                show={showAddModal}
                setShow={setShowAddModal}
                type={ModalTypes.Edit}
                method='PUT'
                endPoint={
                    ''
                    // `admin/companies/${clinic.specialtyId}/clinic`
                }
                customApi={customApi}
                inputs={forms}
                onSuccess={onSuccess}
            />
        </div>
    );
};

export default React.memo(CompaniesClinics);

const Actions = ({ clinic, getCompany }: Record<string, any>): JSX.Element => {
    // company: Record<string, any>
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [form, setForm] = useState({
        doctorName: '',
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: { [value: string]: null | boolean | string } }>({
        doctorName: {
            value: null,
            msg: null,
        },
    });

    const [isActiveData, isActiveLoading, isActiveError, isActiveExecute] = useFetch();

    const forms = [
        {
            name: 'Doctor Name',
            type: InputTypes.TEXT,
            value: form.doctorName,
            name2: 'name',
            check: formErrors.doctorName,
            key: 'doctorName',
            label: 'Doctor Name',
        },
    ];

    const toggleEditClinicName = () => {
        setShowEditModal((prev) => !prev);
    };

    const onSuccess = useCallback(
        (data: any) => {
            console.log('received data: ', data);

            setShowEditModal(false);
            getCompany();
        },
        [getCompany],
    );

    const toggleIsActive = () => {
        console.log('clinic: ', clinic);

        const body = {
            isActive: clinic.isActive === 0 ? 1 : 0,
        };
        isActiveExecute('PUT', `admin/companies/${clinic.specialtyId}/clinic/${clinic.clinicId}/isActive`, body, customApi);
    };

    useEffect(() => {
        if (Object.keys(isActiveData).length > 0) {
            console.log('isActiveData: ', isActiveData);
            getCompany();
        }
    }, [isActiveData, getCompany]);
    useEffect(() => {
        if (Object.keys(isActiveError).length > 0) {
            console.log('isActiveError: ', isActiveError);
        }
    }, [isActiveError]);

    return (
        <>
            <div className={styles['actions-btns-container']}>
                <button className={styles['action-btn']} onClick={toggleIsActive}>
                    {clinic.isActive ? 'Set Deactivate' : 'Set Activate'}
                </button>

                <button className={styles['action-btn']} onClick={toggleEditClinicName}>
                    Edit
                </button>
            </div>

            <ModalComp
                title='Edit clinic'
                show={showEditModal}
                setShow={setShowEditModal}
                type={ModalTypes.Edit}
                method='PUT'
                endPoint={`admin/companies/${clinic.specialtyId}/clinic/${clinic.clinicId}/doctorName`}
                customApi={customApi}
                inputs={forms}
                onSuccess={onSuccess}
            />
        </>
    );
};
