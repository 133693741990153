import { formInputTypesEnum, FormLayout, modalAlert } from '@curawella/admin-package';
import { useContext, useState } from 'react';
import { ctx } from '../../contexts/userContext';
import AdminsModal from '../../models/admins';

const ChangeAdminPass = () => {
    const { user } = useContext(ctx);
    const [err, setErr] = useState('');
    const [loading, execute] = AdminsModal.changePass();

    function handleSubmit(e: React.FormEvent<HTMLFormElement>, data: Record<string, string>) {
        const body = { uid: user.uid, newPassword: '', password: '' };

        if (data.confirmPassword === data.newPassword) {
            body.newPassword = data.newPassword;
        } else {
            setErr("New password and it's confirmation should be the same");
            return;
        }
        setErr('');

        if (data.password) {
            body.password = data.password;
        }

        execute(body)
            .then(() => {
                modalAlert({ type: 'SUCCESS', description: 'Change password successfully' });
            })
            .catch(() => modalAlert({ type: 'ERROR', description: `Wrong password` }));
    }

    return (
        <div>
            <FormLayout
                inputs={[
                    { name: 'password', title: 'Current password', type: formInputTypesEnum.PASSWORD, required: true },
                    { name: 'newPassword', type: formInputTypesEnum.PASSWORD, required: true },
                    { name: 'confirmPassword', errorMessage: err, type: formInputTypesEnum.PASSWORD, required: true },
                ]}
                loading={loading}
                onSubmit={handleSubmit}
            />
        </div>
    );
};
export default ChangeAdminPass;
