import React from 'react';
import { useHistory } from 'react-router-dom';

import { Btn } from '@curawella/admin-package';

import styles from './Patient.module.scss';

type ButtonsListType = {
    patientUid: string;
    resetPassword: () => void;
};

const ButtonsList = ({ patientUid, resetPassword }: ButtonsListType): JSX.Element => {
    const history = useHistory();

    return (
        <div className={styles['button-list-container']}>
            <Btn active handleClick={resetPassword} disabled={false} loading={false}>
                {/* reset password button */}
                Reset Password
            </Btn>

            {/* appointments */}
            <Btn active handleClick={() => history.push(`/patient/${patientUid}/appointments`)} disabled={false} loading={false}>
                Appointments
            </Btn>

            {/* cash box */}
            <Btn active handleClick={() => history.push(`/patient/${patientUid}/cash-box`)} disabled={false} loading={false}>
                Cash Box
            </Btn>
        </div>
    );
};

export default ButtonsList;
