// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wx7VaPvSAGc_BUhg3UC0{min-width:200px;padding:.75rem;border-radius:.5rem;font-size:1.3rem;border:none;margin:1rem;color:#fff;background-color:#01b1be}.Wx7VaPvSAGc_BUhg3UC0:focus-visible{outline:none}", "",{"version":3,"sources":["webpack://./src/components/Select/Select.module.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA,sBACI,eAAA,CACA,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,WAAA,CACA,WAAA,CACA,UAAA,CACA,wBCGM,CDDV,oCACI,YAAA","sourcesContent":["@import '../../assets/styles/colors.scss';\n\n.select-pending {\n    min-width: 200px;\n    padding: 0.75rem;\n    border-radius: 0.5rem;\n    font-size: 1.3rem;\n    border: none;\n    margin: 1rem;\n    color: white;\n    background-color: $primary;\n}\n.select-pending:focus-visible {\n    outline: none;\n}\n","// $primary: #31c2e1;\n// $secondary: #44a8de;\n\n// $gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);\n\n// $bg: #f8fefe;\n\n// $border: #f0fbfd;\n\n$text-dark: #434343;\n\n// $text-light: #808080;\n\n$primary: #01b1be;\n$secondary: #029ca8;\n$input-shadow: #01b1be25;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-pending": "Wx7VaPvSAGc_BUhg3UC0"
};
export default ___CSS_LOADER_EXPORT___;
