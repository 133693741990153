import moment from 'moment';

export const getBoolean = (value: number): boolean => (value === 1 ? true : false);
export const getDate = (value: string): string => moment(value).format('DD-MM-YYYY');
export const getTime = (value: string): string => moment(value).format('HH:MM A');
export const getDateTime = (value: string): string => moment(value).format('DD-MM-YYYY hh:mm a');
export const getActiveState = (value: number | string): string => (value === 1 ? 'Active' : 'Disabled');
export const getGender = (value: number | string): string => (value === 1 ? 'Male' : 'Female');
export const getStatus = (value: number | string): string => (value === 1 ? 'Done' : 'Waiting');
export const getIdBasedOnType = (value: string): string => {
    switch (value) {
        case 'ad':
            return 'ADId';

        case 'doctor':
            return 'ADDoctorId';

        case 'specialty':
            return 'ADDoctorSpecialtyId';

        default:
            return 'ADPatientId';
    }
};

export const getRecipients = (value: { doctorName: string }[]): string => {
    let res = '';

    value.forEach(({ doctorName }: { doctorName: string }) => {
        res += `${doctorName}, `;
    });
    return res.slice(0, res.length - 2);
};

export const getRecipientsList = (value: { doctorName: string }[]): string[] => {
    const arr: string[] = [];
    value.forEach((receiver) => {
        arr.push(receiver.doctorName);
    });
    return arr;
};

export const getSpecialtiesList = (value: { specialtyName: string }[]): string[] => {
    const arr: string[] = [];
    value.forEach((specialty) => {
        arr.push(specialty.specialtyName);
    });
    return arr;
};

export const getArticleContentList = (value: { type: 'PARAGRAPH' | 'TITLE' | 'IMAGE'; content: string }[]): { type: 'TEXT' | 'IMAGE'; value: string }[] => {
    console.log('VALUE: ', value);

    const arr: { type: 'TEXT' | 'IMAGE'; value: string }[] = [];
    value.forEach((element) => {
        arr.push({
            type: element.type === 'IMAGE' ? 'IMAGE' : 'TEXT',
            value: element.content,
        });
    });

    console.log('ARR: ', arr);

    return arr;
};

export const formatValue = (key: string, value: string | number): any => {
    if (key === 'image') {
        return [{ link: value }];
    } else if (key === 'timestamp' || key === 'createdAt') {
        return getDateTime(value as string);
    } else if (key === 'isActive') {
        return getActiveState(value);
    } else if (key === 'position') {
        return getAdminType(value as string);
    }

    return value;
};

export const titles: any = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    createdAt: 'Created',
    phone: 'Phone',
    position: 'Position',
    isActive: 'Status',
    emailId: 'Email Id',
    timestamp: 'Date',
    content: 'Content',
    receivers: 'Receivers',
    ADDoctorSpecialtyId: 'AD Doctor Specialty Id',
    specialtyId: 'Specialty Id',
    image: 'Image',
    title: 'Title',
    creatorName: 'Creator Name',
    ADDoctorId: 'AD Doctor Id',
    clinicId: 'Clinic Id',
    ADPatientId: 'AD Patient Id',
    gender: 'Gender',
    blogId: 'Blog Id',
    doctorArticleId: 'Doctor Article Id',
    overviewImage: 'Overview Image',
    overview: 'Overview',
    elements: 'Elements',
    SMSId: 'SMS Id',
    notificationId: 'Notification Id',
    type: 'Type',
};

export const requestsTypes: any = {
    SMS: 'Sms',
    NOTIFICATIONS: 'Notifications',
    DOCTOR_ARTICLES: 'Doctor Articles',
    PATIENT_BLOGS: 'Patient Blogs',
    DOCTOR_ADS: 'Doctor Ads',
    DOCTOR_SPECIALTY_ADS: 'Doctor Specialty Ads',
    PATIENT_ADS: 'Patient Ads',
    EMAILS: 'Emails',
};

export const adminTypes: any = {
    ADMIN: 'Admin',
    SUPER_ADMIN: 'Super Admin',
};
export const getAdminType = (value: string): string => adminTypes[value] || value;
export const getTitle = (value: string): string => titles[value] || value;
export const getRequestType = (value: string): string => requestsTypes[value] || value;

export const extractRequiredKeys = (elementType: string): string[] => {
    let keys: string[] = [];
    switch (elementType) {
        case 'SMS':
            keys = ['SMSId', 'timestamp', 'content', 'receivers'];
            break;

        case 'NOTIFICATIONS':
            keys = ['notificationId', 'timestamp', 'title', 'content', 'type', 'receivers'];
            break;
        case 'DOCTOR_ARTICLES':
            keys = ['doctorArticleId', 'title', 'overviewImage', 'overview', 'timestamp', 'isActive', 'creatorName', 'elements'];
            break;
        case 'PATIENT_BLOGS':
            keys = ['blogId', 'title', 'image', 'content', 'timestamp', 'isActive', 'specialties'];
            break;

        case 'DOCTOR_ADS':
            keys = ['ADDoctorId', 'clinicId', 'createdAt', 'isActive', 'title', 'image', 'content', 'creatorName'];
            break;
        case 'DOCTOR_SPECIALTY_ADS':
            keys = ['ADDoctorSpecialtyId', 'specialtyId', 'createdAt', 'isActive', 'title', 'image', 'content', 'creatorName'];
            break;

        case 'PATIENT_ADS':
            keys = ['ADPatientId', 'specialtyId', 'createdAt', 'gender', 'isActive', 'title', 'image', 'content', 'creatorName'];
            break;
        case 'EMAILS':
            keys = ['emailId', 'timestamp', 'content', 'receivers'];
            break;
        default:
            break;
    }

    return keys;
};
