import styles from './ContactUs.module.scss';
import { TableComp, useFetch } from '@curawella/admin-package';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Select from '../../components/Select/Select';

const tableData = {
    title: 'Contact Us',
    search: {
        show: true,
    },
    headers: [
        { title: 'App', key: 'appName' },
        { title: 'Subject', key: 'subject' },
        { title: 'Phone', key: 'phone' },
        { title: 'Time', key: 'timestamp' },
    ],
    pagination: {
        show: true,
        pageLength: 10,
        showFirst: false,
        showLast: false,
    },
    showDataLength: true,
};
const selectOptions = ['Pending', 'Resolved'];

const ContactUs = (): JSX.Element => {
    const [data, loading, error, execute] = useFetch();

    const [renderData, setRenderData] = useState([]);
    const [selected, setSelected] = useState(selectOptions[0]);

    const history = useHistory();

    // =================GET CONTACT US DATA=================
    const getContactUsData = useCallback(() => {
        const isPending = selected === selectOptions[0] ? true : false;
        execute('get', `admin/contactus/${isPending}`);
    }, [execute, selected]);

    // =================IS PENDING CHANGED=================
    useEffect(() => {
        getContactUsData();
    }, [getContactUsData, selected]);

    // =================GETTING CONTACT US DATA=================
    useEffect(() => {
        getContactUsData();
    }, [getContactUsData]);

    // =================DOCTORS CONTACT US RECEIVED=================
    useEffect(() => {
        const arr: any = [];
        console.log('data: ', data);
        setRenderData([]);
        Object.entries(data).forEach((item: any) => {
            arr.push({
                ...item[1],
                timestamp: moment(item[1].timestamp * 1000).format('DD/MM/YYYY , LT'),
            });
        });
        setRenderData(arr);
    }, [data]);

    // =================DOCTORS CONTACT US ERROR=================
    useEffect(() => {
        console.log('error: ', error);
    }, [error]);

    const logRow = (row: any) => {
        console.log('row: ', row);
        history.push({
            pathname: '/ContactUsDetails/',
            state: { ...row, isPending: selected === selectOptions[0] ? true : false },
        });
    };

    return (
        <div className={styles['contactus-container']}>
            <Select selectedOption={selected} options={selectOptions} handleChange={(selected) => setSelected(selected)} />

            {loading && <p>loading...</p>}
            {renderData.length > 0 && (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderData}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                    rowAction={logRow}
                />
            )}
        </div>
    );
};

export default ContactUs;
