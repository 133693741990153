// eslint-disable-next-line @typescript-eslint/no-var-requires
const countrycitystatejson = require('countrycitystatejson');
import { egGovAr } from '../constants';


// return cities of selected country and its phone code
export function GetCities(country: string): Record<string, string> {
    let cities: Record<string, string> = {};

    // get all countries data array
    const allCountries: { [key: string]: any }[] = countrycitystatejson.getCountries();

    for (let i = 0; i < allCountries.length; i++) {
        const element = allCountries[i];

        if (country === element.native) {
            const shortName = element.shortName;

            if (shortName === 'EG') {
                cities = egGovAr;
            } else {
                const citiesArr = countrycitystatejson.getStatesByShort(shortName);
                for (let i = 0; i < citiesArr.length; i++) {
                    const element = citiesArr[i];
                    cities = { ...cities, [element]: element }
                }
            }
        }
    }

    return cities;
}
