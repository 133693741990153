import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Btn, DataView, InputTypes, modalAlert } from '@curawella/admin-package';

import styles from './ViewAdmin.module.scss';
import LoginModel from '../../models/login';
import AdminsModal, { getAdminDetails } from '../../models/admins';

import { customDataType, HandleData } from '../../methods/HandleData';
import { toggleObject } from '../../methods/HandleDataFormatting';

import Loading from '../../components/Loading';
import { adminPositions } from '../../constants';

const ViewAdmin: React.FC = () => {
    const { adminUid } = useParams<{ adminUid: string }>();

    const [resetPasswordLoading, executeResetPassword] = LoginModel.passwordReset();
    const [loading, executeGetAdmin, adminData] = AdminsModal.getAdminDetails();

    const [formData, setFormData] = useState<any[]>([]);
    const [permissionsFormData, setPermissionsFormData] = useState<any[]>([]);

    /**
     * Handle admin data rendering
     */
    const handleData = useCallback(() => {
        const { firstName, lastName, isActive, createdAt, position } = adminData;

        const custom: { [k: string]: customDataType } = {
            uid: { editable: false },
            name: {
                title: 'NAME',
                value: `${firstName} ${lastName}`,
                inputs: [
                    { key: 'firstName', label: 'FirstName', value: firstName },
                    { key: 'lastName', label: 'LastName', value: lastName },
                ],
            },
            position: {
                value: position,
                inputs: [{ key: 'position', label: 'Position', type: InputTypes.SELECT, options: adminPositions, value: adminPositions[position] }],
            },
            phone: {},
            email: {},
            createdAt: {
                editable: false,
                value: moment(createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY hh:mm A'),
            },
            isActive: toggleObject(isActive, false),
        };

        const handledData = HandleData(adminData, custom, `admin/admins/${adminUid}/`);

        setFormData(handledData);
    }, [adminData, adminUid]);

    /**
     * Handle admin permissions data for rendering
     */
    const handlePermissionsData = useCallback(() => {
        const { ADMINS, DRUGS, DOCTORS, PATIENTS, EARNINGS, CONTACTUS, SPECIALTIES, NOTIFICATIONS, SUBSCRIPTION } = adminData;

        const custom: { [k: string]: customDataType } = {
            ADMINS: toggleObject(ADMINS, false),
            DRUGS: toggleObject(DRUGS, false),
            DOCTORS: toggleObject(DOCTORS, false),
            PATIENTS: toggleObject(PATIENTS, false),
            EARNINGS: toggleObject(EARNINGS, false),
            CONTACTUS: toggleObject(CONTACTUS, false),
            SPECIALTIES: toggleObject(SPECIALTIES, false),
            NOTIFICATIONS: toggleObject(NOTIFICATIONS, false),
            SUBSCRIPTION: toggleObject(SUBSCRIPTION, false),
        };

        const handledData = HandleData(adminData, custom, `admin/admins/permissions/${adminUid}/`);

        setPermissionsFormData(handledData);
    }, [adminData, adminUid]);

    /**
     * Get Admin details
     */
    const getAdmin = useCallback((): void => {
        const data: getAdminDetails = { adminUid };

        executeGetAdmin(data);
    }, [adminUid, executeGetAdmin]);

    // Call getAdmin function to get Admin details
    useEffect(() => getAdmin(), [getAdmin]);

    // Call handleData & handlePermissionsData functions to handel admin data for rendering
    useEffect(() => {
        if (adminData) {
            handleData();
            handlePermissionsData();
        }
    }, [adminData, handleData, handlePermissionsData]);

    /**
     * Reset password
     */
    const resetPassword = (): void => {
        executeResetPassword({ uid: adminUid }).then((res) => {
            modalAlert({
                type: 'SUCCESS',
                description: `password has been reset successfully and your new password is ${res.password} `,
            });
        });
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles['view-admin-container']}>
            {/* header */}
            <h3>View admin details</h3>

            {/* data */}
            <DataView content={formData} onSuccess={() => getAdmin()} />

            <h3 className={styles['sub-title']}>Permissions</h3>
            <DataView content={permissionsFormData} onSuccess={() => getAdmin()} />

            {/* Reset Password button */}
            <Btn active handleClick={resetPassword} disabled={resetPasswordLoading} className={styles['reset-btn']}>
                Reset Password
            </Btn>
        </div>
    );
};

export default ViewAdmin;
