// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GEjzgB0xC5GaoMj6iUI7 .oyKlL5AwdtNVgXSaCVlh{width:450px;margin:20px 0px;display:flex;align-items:center;justify-content:space-between}.GEjzgB0xC5GaoMj6iUI7 .oyKlL5AwdtNVgXSaCVlh .tT_rOqU5Vbf0Y1FIizlg{background:transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%) 0% 0% no-repeat padding-box;color:#fff}.GEjzgB0xC5GaoMj6iUI7 .oyKlL5AwdtNVgXSaCVlh .Qt7cZlFJPX15MSgdBj0B{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/pages/subscriptions/subscription-details/SubscriptionDetails.module.scss"],"names":[],"mappings":"AACI,4CACI,WAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kEACI,qGAAA,CACA,UAAA,CAEJ,kEACI,yBAAA","sourcesContent":[".edit-subscription-container {\n    .flex-parent {\n        width: 450px;\n        margin: 20px 0px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        .cancel-btn {\n            background: transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%) 0% 0% no-repeat padding-box;\n            color: white;\n        }\n        .btn {\n            text-transform: capitalize;\n            // width: max-content;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit-subscription-container": "GEjzgB0xC5GaoMj6iUI7",
	"flex-parent": "oyKlL5AwdtNVgXSaCVlh",
	"cancel-btn": "tT_rOqU5Vbf0Y1FIizlg",
	"btn": "Qt7cZlFJPX15MSgdBj0B"
};
export default ___CSS_LOADER_EXPORT___;
