// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dW70xvv7OU13LpUgQKt_{display:flex;flex-direction:column}.dW70xvv7OU13LpUgQKt_ p{font-size:1.5rem;align-self:flex-start}.dW70xvv7OU13LpUgQKt_ button,.dW70xvv7OU13LpUgQKt_ input[type=submit]{min-width:200px;padding:.5rem 1rem;border-radius:2rem;font-size:1.5rem;color:#fff;border:none;margin:1rem;color:#fff;background-color:#01b1be;align-self:flex-end}.dW70xvv7OU13LpUgQKt_ input[type=submit]{align-self:flex-start;font-size:1.25rem}", "",{"version":3,"sources":["webpack://./src/pages/notifications/add-notification-app/AddNotificationApp.module.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,qBAAA,CACA,wBACI,gBAAA,CACA,qBAAA,CAEJ,sEAEI,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAEA,UAAA,CACA,wBCPE,CDQF,mBAAA,CAGJ,yCACI,qBAAA,CACA,iBAAA","sourcesContent":["@import '../../../assets/styles/colors.scss';\n\n.add-notification-container {\n    display: flex;\n    flex-direction: column;\n    p{\n        font-size: 1.5rem;\n        align-self: flex-start;\n    }\n    button,\n    input[type='submit'] {\n        min-width: 200px;\n        padding: 0.5rem 1rem;\n        border-radius: 2rem;\n        font-size: 1.5rem;\n        color: white;\n        border: none;\n        margin: 1rem;\n\n        color: white;\n        background-color: $primary;\n        align-self: flex-end;\n    }\n\n    input[type='submit'] {\n        align-self: flex-start;\n        font-size: 1.25rem;\n    }\n}\n","// $primary: #31c2e1;\n// $secondary: #44a8de;\n\n// $gradient: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);\n\n// $bg: #f8fefe;\n\n// $border: #f0fbfd;\n\n$text-dark: #434343;\n\n// $text-light: #808080;\n\n$primary: #01b1be;\n$secondary: #029ca8;\n$input-shadow: #01b1be25;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-notification-container": "dW70xvv7OU13LpUgQKt_"
};
export default ___CSS_LOADER_EXPORT___;
