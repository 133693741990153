import { Header, Loading, TableComp } from '@curawella/admin-package';
import moment from 'moment';
import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ctx } from '../../contexts/userContext';
import EmailsModel, { getEmailsType } from '../../models/emails';

const pageSize = 10;

const Emails = () => {
    const { user } = useContext(ctx);

    const [loadingEmails, executeEmails, EmailsData] = EmailsModel.getEmails();
    const Emails = EmailsData || [];

    const [dataLength, setDataLength] = useState<number>(0);
    const history = useHistory();

    /**
     * Get Emails
     */
    const getEmails = useCallback(
        (pageNumber = undefined) => {
            const d: getEmailsType = { senderUid: user.uid, pageSize: String(pageSize) };

            if (pageNumber) {
                d.pageNumber = pageNumber;
            }
            executeEmails(d)
                .then((res) => {
                    setDataLength(res.length);
                })
                .catch((err) => console.log('get Emails err: ', err));
        },
        [executeEmails, user.uid],
    );

    return (
        <div>
            {/* header */}
            <Header title='Emails' button={{ title: 'Create', onClick: () => history.push(`/sendEmail`) }} />

            {/* loading */}
            {loadingEmails && <Loading />}

            {/* emails data */}
            <TableComp
                title={'Emails'}
                headers={[
                    { key: 'id' },
                    { key: 'subject' },
                    {
                        key: 'createdAt',
                        format: (v: any) => moment(v).format('LLLL'),
                    },
                ]}
                data={Emails}
                pagination={{ pageLength: pageSize, showFirst: false, showLast: false }}
                showDataLength
                rowAction={(Emails) => history.push(`/emails/${Emails.id}`)}
                endpoint={getEmails}
                dataLength={dataLength}
                search={false}
            />
        </div>
    );
};

export default Emails;
