import backendConfig from './backendConfig';

export type addDrugType = {
    type?: string;
    ndc?: string;
    commercialName: string;
    scientificName: string;
    labelerName?: string;
    dosageForm?: string;
    route?: string;
    dose?: string;
};

export type getDrugsType = {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
};

export type getDrugsResponse = { commercialName: string; drugId: number; ndc: string };

export type getDrugType = {
    drugId: number | string;
};

export type disableDrugType = {
    drugId: number | string;
    active: boolean;
};

export const routesOptions = [
    { title: 'Select', value: '' },
    'ORAL',
    'TOPICAL',
    'INTRAVENOUS',
    'INTRAMUSCULAR',
    'DENTAL',
    'OPHTHALMIC',
    'SUBCUTANEOUS',
    'INTRADERMAL',
    'RESPIRATORY (INHALATION)',
    'PERCUTANEOUS',
    'NASAL',
    'SUBLINGUAL',
    'EXTRACORPOREAL',
    'TRANSDERMAL',
    'RECTAL',
    'CUTANEOUS',
    'VAGINAL',
    'EPIDURAL',
    'AURICULAR (OTIC)',
    'BUCCAL',
    'INFILTRATION',
    'INTRA-ARTICULAR',
];

export const dosageFormOptions = [
    { title: 'Select', value: '' },
    'TABLET',
    'LIQUID',
    'GEL',
    'SOLUTION',
    'CAPSULE',
    'CREAM',
    'PELLET',
    'LOTION',
    'SPRAY',
    'KIT',
    'CLOTH',
    'INJECTION',
    'OINTMENT',
    'SUSPENSION',
    'STICK',
];

class DrugModel {
    static addDrug() {
        return backendConfig.config.postData<addDrugType>({
            endPoint: 'admin/drugs',
            bodyDefault: {
                ndc: '',
                commercialName: '',
                scientificName: '',
                dosageForm: '',
                route: '',
                dose: '',
                labelerName: '',
                type: '',
            },
        });
    }

    static getDrugs() {
        return backendConfig.config.getData<getDrugsType>({
            endPoint: 'admin/drugs',
            queryDefault: { pageNumber: '1', pageSize: '10', search: '' },
        });
    }

    static getDrug() {
        return backendConfig.config.getData<getDrugType>({ endPoint: 'admin/drugs/:drugId' });
    }

    static disableDrug() {
        return backendConfig.config.updateData<getDrugType>({ endPoint: 'admin/drugs/disable/:drugId', bodyDefault: { active: false } });
    }
}

export default DrugModel;
